import { useEffect, useRef, useState } from 'react'

import { SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useAppDispatch } from "store"
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { photoPreviewTransformerActions } from "./photoPreviewTransformerSlice"
import { getCurrentPhotoPreviewTransformerItemIndexSelector,
  getPhotoPreviewTransformerItemsSelector } from "./selectors"

export const PhotoPreviewTransformerToolbar = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const items = getPhotoPreviewTransformerItemsSelector()
  const currentIndex = getCurrentPhotoPreviewTransformerItemIndexSelector()
  // const sdkType = getCurrentPhotoPreviewSdkTypeSelector()

  const ref = useRef(null)

  const [currentItem, setCurrentItem] = useState<any>(items[currentIndex])

  useEffect(() => {
    setCurrentItem(items[currentIndex])
  }, [currentIndex, items])

  const handleClose = () => {
    dispatch(photoPreviewTransformerActions.setVisiblePreview(false))
    dispatch(photoPreviewTransformerActions.setCurrentItemIndex(0))
    dispatch(photoPreviewTransformerActions.setError(false))
  }

  const handleDownload = () => {
    window.location.href = currentItem.results[0]
  }

  const actionItems = (() => {
    const items = [
      {
        handler: handleDownload,
        title: t('a_common_download'),
        iconName: 'download',
      },
      {
        handler: handleClose,
        title: t('a_common_close'),
        iconName: 'close',
      }
    ].filter(item => !!item)

    return items
  })()

  return (
    <PhotoPreviewToolbarBox className={className}
      ref={ref}
    >
      {
        actionItems.map(item => (
          <ActionItem
            key={item.title}
            item={item}
          />
        ))
      }
    </PhotoPreviewToolbarBox>
  )
}

const ActionItem = ({ className = '', item }) => {
  const [popoverOpened, togglePopover] = useState(false)

  const handleClick = (event: MouseEvent) => {
    if (item.popoverContent) {
      togglePopover(state => !state)
    }

    item.handler(event)
  }

  return (
    <ActionIconBox
      onClick={handleClick}
      title={item.title}
      className={classNames(className, { 'hidden-on-mobile': item.hiddenOnMobile, 'hidden-on-desktop': item.hiddenOnDesktop })}
      orderOnMobile={item.orderOnMobile}
    >
      <ActionIcon iconName={item.iconName} />

      {!!item.popoverContent && popoverOpened && (
        <Popover>
          {item.popoverContent}
        </Popover>
      )}
    </ActionIconBox>
  )
}

const PhotoPreviewToolbarBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ActionIconBox = styled.button`
  width: 44px;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .3s ease;
  position: relative;
  z-index: 2;
  color: #DADADA;
  order: ${props => props.orderOnMobile};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background-color: rgba(255, 255, 255, .34);
  }

  &.hidden-on-mobile {
    display: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    order: unset;

    &.hidden-on-desktop {
      display: none;
    }

    &.hidden-on-mobile {
      display: flex;
    }
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP}) {
    &:hover {
      background-color: unset;
    }
  }
`

const ActionIcon = styled(SpriteIcon)`
  && {
    path {
      fill: white;
    }
  }
`

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: auto;
  right: 0;
  background: #fff;
  border-radius: 6px;
  width: 180px;
  color: #000;
  font-size: 12px;
  line-height: 1.4;
  padding: 9px 14px;
  text-align: left;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

  &.active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: solid;
    left: auto;
    right: 11px;
    top: -21px;
    bottom: auto;
    margin-left: -11px;
    border-width: 11px;
  }
`
