import { uniqueId } from "lodash"

import { INotification, NotificationsListener } from "./types"

const notifications: INotification[] = []

const listeners: NotificationsListener[] = []

let DEFAULT_TIMEOUT_TO_HIDE = 5000
let DEFAULT_TIMEOUT_SHOW_DELAY = 500

const callAllListeners = () => {
  listeners.forEach(listener => {
    listener([...notifications])
  })
}

export const setTimeoutToHideNotification = (timeout: number) => {
  DEFAULT_TIMEOUT_TO_HIDE = timeout
}

export const setTimeoutForDelayedDisplay = (timeout: number) => {
  DEFAULT_TIMEOUT_SHOW_DELAY = timeout
}

export const addNotificationsListener = (listener: NotificationsListener) => {
  listeners.push(listener)
}

export const showNotification = (notification: INotification) => {
  const notificationWithId = { ...notification, id: uniqueId() }
  let showWithDelay = false

  if (!!notificationWithId.typeError && notifications.some(item => item.typeError === notificationWithId.typeError)) {
    const hiddenNotification = notifications.find(item => item.typeError === notificationWithId.typeError)
    const hiddenNotificationIndex = notifications.indexOf(hiddenNotification)
    showWithDelay = true
    hideNotification(notifications[hiddenNotificationIndex])
  }

  if (notifications.length >= 3 ) {
    hideNotification(notifications[0])
  }

  if (showWithDelay) {
    setTimeout(() => {
      notifications.push(notificationWithId)
      callAllListeners()
      showWithDelay = false
    },  DEFAULT_TIMEOUT_SHOW_DELAY)
  } else {
    notifications.push(notificationWithId)
    callAllListeners()
  }

  if (!notification.isPermanent) {
    setTimeout(() => {
      const existedNotification = notifications.find(n => n.id === notificationWithId.id)
      const indexOfNotification = notifications.indexOf(existedNotification)

      notifications.splice(indexOfNotification, 1)

      callAllListeners()
    }, notification.timeoutToHide || DEFAULT_TIMEOUT_TO_HIDE)
  }

  return notificationWithId
}

export const hideNotification = (notification: INotification) => {
  const existedNotification = notifications.find(n => n.id === notification.id)
  const indexOfNotification = notifications.indexOf(existedNotification)

  if (!existedNotification) {
    return
  }

  notifications.splice(indexOfNotification, 1)

  callAllListeners()
}
