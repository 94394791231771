import { SIDEBAR_LOGO_URL, SIDEBAR_LOGO_URL_DARK } from "constants/configs/theme.config"

import styled from 'styled-components'

import { THEME } from "../../../../features/user/userSlice"

const SidebarLogo = styled.span`
  display: block;
  width: 116px;
  height: 70px;
  margin-left: 16px;
  background: ${props => `url("${props.url}") no-repeat`};
  background-size: cover;
`

export default SidebarLogo
