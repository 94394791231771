import { request } from "./request"

export const offersApi = {
  getOffers: async (offerId: string) => {
    return request('GET', `/api/2/offers/${offerId}`)
  },
  getOfferContent: async (offerId): Promise<{content: string, url: string}> => {
    const { content, url } = await offersApi.getOffers(offerId)
    return { content, url }
  },
  getOffersData: async (data) => {
    return request('GET', `/api/2/offers/`, data)
  },
}
