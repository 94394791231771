import { LOADING_STATUSES } from 'constants/loadingStatuses'

import React, { useMemo, useState } from 'react'

import { CreateNewFolderForm, MenuWithActionItems, PrimaryButton, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { useNavigate } from 'react-router-dom'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { getUiTheme, getUserDataSelector } from 'features/user/selectors'
import { updateUserDataThunk } from 'features/user/userSlice'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { appActions } from "../../store/app"
import { getModalsRootElement } from "../../constants/modals"
import { useMobileDetection } from "../common/hooks"

import {
  deleteFamilyCloudThunk,
  deleteFamilyMembersThunk,
  familyActions,
  leaveFromFamilyCloudThunk,
  updateFamilyMemberThunk
} from './familySlice'
import {
  getFamilyLoadingStatusSelector,
  getFamilyMemberSelectedSelector,
  getSelectedMembersSelector
} from './selectors'
import { validationSchemaRenameMember } from "./validationSchemaRenameMember"


interface ManageFamilyToolbarProps {
  className?: string,
}

export const ManageFamilyToolbar: React.FC<ManageFamilyToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = getUiTheme()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)
  const [removingMemberConfirmationModalOpened, toggleRemovingMemberConfirmationModal] = useState(false)
  const [leavingConfirmationModalOpened, toggleLeavingConfirmationModal] = useState(false)
  const [renameMemberModalOpened, toggleRenameMemberModal] = useState(false)
  const [tmpMemberName, setTmpMemberName] = useState('')

  const userData = getUserDataSelector()
  const status = getFamilyLoadingStatusSelector()
  const selectedMembersIds = getFamilyMemberSelectedSelector()
  const selectedMembers = getSelectedMembersSelector()
  const selectedMembersNames = selectedMembers.map(member => member.name).join(', ')
  const isAnyMemberSelected = selectedMembersIds.length > 0
  const isOwnerSelected = selectedMembers.some(member => member.role === 'owner')
  const isOneMemberSelected = selectedMembersIds.length === 1
  const isMobile = useMobileDetection()
  const isOwner = userData?.is_owner_family

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingMemberConfirmationModal = () => {
    toggleRemovingMemberConfirmationModal(true)
  }

  const handleCloseRemovingMemberConfirmationModal = () => {
    toggleRemovingMemberConfirmationModal(false)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
  }

  const handleOpenLeavingConfirmationModal = () => {
    toggleLeavingConfirmationModal(true)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleCloseLeavingConfirmationModal = () => {
    toggleLeavingConfirmationModal(false)
  }

  const handleRemoveFamilyCloud = () => {
    const callback = () => {
      dispatch(updateUserDataThunk({ family_user_id: null, is_owner_family: null }))
      navigate('/family/photos')
    }

    dispatch(deleteFamilyCloudThunk(callback))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_FC_MANAGE_DELETE_FC_CLICK)
  }

  const handleLeaveFromFamilyCloud = () => {
    const callback = () => {
      dispatch(updateUserDataThunk({ family_user_id: null, is_owner_family: null }))
      navigate('/family/photos')
    }

    dispatch(leaveFromFamilyCloudThunk({ userId: userData.id, callback }))
  }

  const handleRemovingMembersFromFamilyCloud = () => {

    dispatch(deleteFamilyMembersThunk(selectedMembers))
      .then(() => {
        toggleRemovingMemberConfirmationModal(false)
      })
    dispatch(appActions.toggleRightMenuOnMobile(false))

  }

  const handleOpenInviteModal = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_FC_INVITE)

    dispatch(familyActions.toggleFamilyinviteModal(true))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = useMemo(() => {
    const familyItems = generateOwnerMenuItems()
    const membersItems = generateMemberMenuItems()
    return [...familyItems, ...membersItems]
  }, [isOwner, selectedMembersIds])

  function generateOwnerMenuItems() {
    return [
      isMobile && !isAnyMemberSelected && {
        label: t('a_common_invite'),
        iconName: 'add_from_family',
        onClickItem: handleOpenInviteModal
      },
      isOwner && !isAnyMemberSelected && {
        label: t('a_family_deleteFamilyCloud'),
        iconName: 'delete_forever',
        onClickItem: handleOpenRemovingConfirmationModal
      },
      !isOwner && !isAnyMemberSelected && {
        label: t('a_common_leaveFamilyCloud'),
        iconName: 'exit_to_app',
        onClickItem: handleOpenLeavingConfirmationModal
      }
    ].filter(item => !!item)
  }

  function handleOpenRenameMemberModal() {
    toggleRenameMemberModal(true)
    const selectedMember = selectedMembers[0]
    if (!selectedMember) return
    setTmpMemberName(selectedMember?.name || '')
  }

  function handleCloseRenameMemberModal() {
    toggleRenameMemberModal(false)
    setTmpMemberName('')
  }

  function handleRenameMember(value: string) {
    const selectedMember = selectedMembers[0]
    if (!selectedMember) return
    dispatch(updateFamilyMemberThunk({ member: selectedMembers[0], data: { name: value } }))
      .then(() => {
        handleCloseRenameMemberModal()
        dispatch(familyActions.unselectAll())
        setTmpMemberName('')
      })
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  function generateMemberMenuItems() {
    return [
      isOwner && isAnyMemberSelected && !isOwnerSelected && {
        label: t('a_common_delete'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingMemberConfirmationModal
      },
      isOwner && isOneMemberSelected && !isOwnerSelected && {
        label: t('a_common_edit'),
        iconName: 'edit',
        onClickItem: handleOpenRenameMemberModal
      }].filter(item => !!item)
  }

  const mobileMenuItems = (() => {
    return menuItems
  })()


  if (status === LOADING_STATUSES.LOADING || !userData?.family_user_id) {
    return null
  }

  return (
    <>
      <ManageFamilyToolbarBox className={className}>
        {
          !isAnyMemberSelected && (
            <StyledPrimaryButton
              actionName={t('a_common_invite')}
              onAction={() => handleOpenInviteModal()}
            />
          )
        }

        <MenuWithActionItems
          isBorder={isOwner && !isAnyMemberSelected}
          menuItems={menuItems}
        />
      </ManageFamilyToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={null}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_family_deleteFamilyCloudTitle')}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_remove')}
        onOk={handleRemoveFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_confirmationToRemoveFamily')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={leavingConfirmationModalOpened}
        title={t('l_familycloud_manageFamily')}
        onClose={handleCloseLeavingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleLeaveFromFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseLeavingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_leaveApp')}
        </TextInModalBox>
      </DialogModal>

      <DialogModal
        isOpened={removingMemberConfirmationModalOpened}
        title={t(`l_familycloud_deleteMembers`, { member: selectedMembersNames, number: selectedMembers.length })}
        onClose={handleCloseRemovingMemberConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemovingMembersFromFamilyCloud}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingMemberConfirmationModal}
      >
        <TextInModalBox>
          {t(`l_familycloud_deleteMembersConfirm`, { member: selectedMembersNames, number: selectedMembers.length })}
        </TextInModalBox>
      </DialogModal>

      <CreateNewFolderForm
        theme={theme}
        texts={{
          TITLE: t('l_common_editName'),
          SUB_TITLE: t('l_common_name'),
          PLACEHOLDER: t('l_common_name'),
          NAME_BUTTON_ACTION: t('a_common_save'),
          NAME_BUTTON_CANCEL: t('a_common_cancel'),
          TITLE_ICON: '',
        }}
        onCreateFolder={handleRenameMember}
        onCloseModal={handleCloseRenameMemberModal}
        isShown={renameMemberModalOpened}
        parentBlock={getModalsRootElement()}
        validationSchema={validationSchemaRenameMember}
        defaultValue={tmpMemberName}
        maxLength={40}
      />

    </>
  )
}

const TextInModalBox = styled.p`
    max-width: 580px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary)
`

const ManageFamilyToolbarBox = styled.div`
    padding: 0 24px;

    @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
        display: none;
    }
`

const StyledPrimaryButton = styled(PrimaryButton)`
    width: 100%;
    padding: 8px 12px;
`
