import { useCallback } from 'react'

import { uploadFavoriteItemsThunk, uploadTimelineItemsThunk } from "../../common/files-uploading/filesUploadingSlice"
import { appActions } from "../../../store/app"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import { useAppDispatch } from "../../../store"

import { TIMELINE_FILTERS } from "./timelineSlice"


export const useUploadTimelineItemsUploadHandler = (type, filter = TIMELINE_FILTERS.all) => {
  const dispatch = useAppDispatch()

  const handleUploadFiles = useCallback((event) => {
    const files = event.target.files

    if (files.length > 0) {
      const callback = () => {
        event.target.value = ''
      }

      if (filter === TIMELINE_FILTERS.favorites) {
        dispatch(uploadFavoriteItemsThunk({ files, type, callback }))
      } else {
        dispatch(uploadTimelineItemsThunk({ files, type, callback }))
      }

      dispatch(appActions.toggleRightMenuOnMobile(false))

      if (type === SDK_TYPES.DEFAULT) {
        analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_UPLOAD_CLICK)
      }

      if (type === SDK_TYPES.FAMILY) {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_PHOTO_UPLOAD_CLICK)
      }
    }
  }, [type, filter, dispatch])

  return handleUploadFiles
}
