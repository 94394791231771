import React, { FC, useEffect, useRef } from 'react'

import { createPortal } from 'react-dom'
import styled from 'styled-components'

import observeRect from "../utils/observeRect"

interface PositionInPortalProps {
  parentRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  children: React.ReactNode;
  topOffset?: number
}

export const PositionInPortal: FC<PositionInPortalProps> = ({ parentRef, children, topOffset }) => {
  const positionedPortalContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const node = positionedPortalContainerRef.current

    if (node && parentRef.current) {
      const { x, y, height, width } = parentRef.current.getBoundingClientRect()

      const { style } = node

      style.top = `${ y + height + (topOffset || 0)}px`
      style.left = `${x}px`
      style.height = `${height}px`
      style.width = `${width}px`
    }
  }, [parentRef, positionedPortalContainerRef])

  useEffect(() => {
    const node = positionedPortalContainerRef.current

    if (node && parentRef.current) {
      const observer = observeRect(parentRef.current, (rect) => {
        if (rect) {
          const { x, y, height, width } = rect

          const { style } = node
          style.top = `${ y + height + (topOffset || 0)}px`
          style.left = `${x}px`
          style.height = `${height}px`
          style.width = `${width}px`
        }
      })

      observer.observe()

      return () => {
        observer.unobserve()
      }
    }
  }, [parentRef, positionedPortalContainerRef])


  return createPortal(
    <SPositionedPortalContainer ref={positionedPortalContainerRef}>
      <SDropDownContainer>
        {children}
      </SDropDownContainer>
    </SPositionedPortalContainer>,
    document.body,
  )
}

const SPositionedPortalContainer = styled.div`
  position: fixed;
  overflow: visible;
  z-index: 10;
`

const SDropDownContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  width: fit-content;
`
