import React, { useEffect, useRef, useState } from "react"

import { Navigate, Outlet, Route, Routes, } from "react-router-dom"
import { RightSidebar } from "features/common/right-sidebar"
import { LeftSidebar } from "features/common/left-sidebar"
import { ConnectedLayout } from "features/common/connected-layout"
import { SharingModal } from "features/sharing/SharingModal"
import { PhotoPreviewModal } from "features/photo/photo-preview/PhotoPreviewModal"
import { FamilyInviteModal } from "features/family/FamilyInviteModal"
import FamilyInvitation from "pages/Family/FamilyInvitation"
import { AUTH_STATUSES, useAuth } from "features/user/hooks/useAuth"
import { PublicAlbumPage } from "pages/Public/Album"
import { PublicLinkSidebar } from "features/public-link/PublicLinkSidebar"
import { FilesPreviewModal } from "features/files/files-preview/FilesPreviewModal"
import { PublicFilesPage } from "pages/Public/Files"
import { PublicLinkFilesSidebar } from "features/public-link-files/PublicLinkFilesSidebar"
import { AddToAlbum } from "features/albums/add-to-album-modal/AddToAlbumModal"
import { useOnClickOutside } from "@cloudike/web_ui_components"

import { PhotoPreviewFlashbackModal } from "../features/photo/photo-preview-flashback/PhotoPreviewFlashbackModal"
import SignIn from "../pages/SignIn"
import OfferView from "../features/offer/OfferView"
import { PhotoPreviewTransformerModal } from "../features/photo/photo-transformer-preview/photoPreviewTransformer"
import { getMobileLeftMenuStatusSelector } from "../features/common/selectors"
import { useMobileDetection } from "../features/common/hooks"

import { routes } from "./routes"

const RightSidebarRouting = () => {
  return (
    <Routes>
      {routes.map((route) => {
        const SidebarComponent = route.rightSidebarComponent

        if (route.withoutRightSidebar) {
          return null
        }

        return (
          <Route
            path={route.path}
            element={<RightSidebar />}
            key={route.path}
          >
            {
              !!route.nestedRoutes && (
                <Route element={<Outlet />}>
                  {route.nestedRoutes.map((route, index) => {
                    const Component = route.rightSidebarComponent

                    if (!Component) {
                      return null
                    }

                    return (
                      <Route
                        index={route.isIndex}
                        path={route.path}
                        key={route.path || index}
                        element={<Component />}
                      />
                    )}
                  )}
                </Route>
              )}

            {
              !route.nestedRoutes && !!route.rightSidebarComponent && (
                <Route
                  index
                  element={<SidebarComponent />}
                />
              )}
          </Route>
        )
      })}

      <Route path="*"
        element={null}
      />
    </Routes>
  )
}

export const Routing = () => {
  const authStatus = useAuth()
  const isLeftSidebarActiveOnMobile = getMobileLeftMenuStatusSelector()

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent
    return /iPad|iPhone|iPod/.test(userAgent) || /android/i.test(userAgent)
  }

  const isTablet = useMobileDetection(1280)
  const isMobile = useMobileDetection()
  const [isHoveredLeftMenuFromArrow, setIsHoveredLeftMenuFromArrow] = useState(isMobileDevice())
  const [isLeftMenuHidden, setIsLeftMenuHidden] = React.useState(false)
  const [isHoveredLeftMenu, setIsHoveredLeftMenu] = useState(false)
  const leftMenuRef = useRef<HTMLElement | null>(null)

  useOnClickOutside(leftMenuRef, () => {
    isTablet && setIsHoveredLeftMenu(false)
  })

  useEffect(() => {
    if ( isTablet ) {
      setIsLeftMenuHidden(true)
      setIsHoveredLeftMenu(true)
    }

    if ( isMobile ) {
      setIsHoveredLeftMenu(isLeftSidebarActiveOnMobile)
    }

    if ( !isMobile && !isTablet ) {
      const element = leftMenuRef.current

      const handleMouseEnter = () => setIsHoveredLeftMenu(true)

      const handleMouseLeave = () => setIsHoveredLeftMenu(false)


      const handleMouseEnterFromArrow = () => setIsHoveredLeftMenuFromArrow(true)
      const handleMouseLeaveFromArrow = () => setIsHoveredLeftMenuFromArrow(false)

      if (element && !isMobileDevice()) {
        element.addEventListener('mouseenter', handleMouseEnterFromArrow)
        element.addEventListener('mouseleave', handleMouseLeaveFromArrow)
      }

      if (isLeftMenuHidden) {
        setIsHoveredLeftMenu(false)
        const timeout = setTimeout(() => {
          if (element && !isMobileDevice()) {
            element.addEventListener('mouseenter', handleMouseEnter)
            element.addEventListener('mouseleave', handleMouseLeave)
          }
        }, 500)


        return () => {
          if (element && !isMobileDevice()) {
            element.removeEventListener('mouseenter', handleMouseEnter)
            element.removeEventListener('mouseleave', handleMouseLeave)
            element.removeEventListener('mouseenter', handleMouseEnterFromArrow)
            element.removeEventListener('mouseleave', handleMouseLeaveFromArrow)
          }
          clearTimeout(timeout)
        }
      } else {
        setIsHoveredLeftMenu(true)
      }
    }

  }, [isTablet, isMobile, isLeftSidebarActiveOnMobile, isLeftMenuHidden, leftMenuRef.current])

  const handleClickMenuButton = () => {
    setIsHoveredLeftMenu(prevState => !prevState)
  }

  return (
    <Routes>
      <Route
        path="/signin"
        element={<SignIn />}
      />

      <Route
        path="/offers/:offerId"
        element={<OfferView />}
      />

      {authStatus !== AUTH_STATUSES.UNKNOWN && (
        <>
          <Route
            path="/invite"
            element={<FamilyInvitation />}
          />

          <Route
            path="/links/pa/:id"
            element={(
              <ConnectedLayout
                isLeftMenuHidden={isLeftMenuHidden}
                isHoveredLeftMenu={isHoveredLeftMenu}
                leftMenuRef={leftMenuRef}
                leftSidebar={(
                  <LeftSidebar
                    isHoveredLeftMenuFromArrow={isHoveredLeftMenuFromArrow}
                    onClickMenuButton={handleClickMenuButton}
                    onClickArrowHidden={() => setIsLeftMenuHidden(prevState => !prevState)}
                    isHoveredLeftMenu={isHoveredLeftMenu}
                    isLeftMenuHidden={isLeftMenuHidden}
                  />
                )}
                rightSidebar={<PublicLinkSidebar />}
              >
                <PublicAlbumPage />

                <PhotoPreviewModal />
              </ConnectedLayout>
            )}
          />

          <Route
            path="/public/*"
            element={(
              <ConnectedLayout
                isLeftMenuHidden={isLeftMenuHidden}
                isHoveredLeftMenu={isHoveredLeftMenu}
                leftMenuRef={leftMenuRef}
                leftSidebar={                  (
                  <LeftSidebar
                    isHoveredLeftMenuFromArrow={isHoveredLeftMenuFromArrow}
                    onClickMenuButton={handleClickMenuButton}
                    onClickArrowHidden={() => setIsLeftMenuHidden(prevState => !prevState)}
                    isHoveredLeftMenu={isHoveredLeftMenu}
                    isLeftMenuHidden={isLeftMenuHidden}
                  />
                )}
                rightSidebar={<PublicLinkFilesSidebar />}
              >
                <PublicFilesPage />

                <FilesPreviewModal />
              </ConnectedLayout>
            )}
          />

          <Route
            path="/links/fs/*"
            element={(
              <ConnectedLayout
                isLeftMenuHidden={isLeftMenuHidden}
                isHoveredLeftMenu={isHoveredLeftMenu}
                leftMenuRef={leftMenuRef}
                leftSidebar={                  (
                  <LeftSidebar
                    onClickMenuButton={handleClickMenuButton}
                    onClickArrowHidden={() => setIsLeftMenuHidden(prevState => !prevState)}
                    isHoveredLeftMenu={isHoveredLeftMenu}
                    isLeftMenuHidden={isLeftMenuHidden}
                    isHoveredLeftMenuFromArrow={isHoveredLeftMenuFromArrow}
                  />
                )}
                rightSidebar={<PublicLinkFilesSidebar />}
              >
                <PublicFilesPage />

                <FilesPreviewModal />
              </ConnectedLayout>
            )}
          />
        </>
      )}

      {authStatus === AUTH_STATUSES.AUTHORIZED && (
        <Route
          path="*"
          element={(
            <ConnectedLayout
              isLeftMenuHidden={isLeftMenuHidden}
              isHoveredLeftMenu={isHoveredLeftMenu}
              leftMenuRef={leftMenuRef}
              leftSidebar={                  (
                <LeftSidebar
                  onClickMenuButton={handleClickMenuButton}
                  onClickArrowHidden={() => setIsLeftMenuHidden(prevState => !prevState)}
                  isHoveredLeftMenu={isHoveredLeftMenu}
                  isLeftMenuHidden={isLeftMenuHidden}
                  isHoveredLeftMenuFromArrow={isHoveredLeftMenuFromArrow}
                />
              )}
              rightSidebar={<RightSidebarRouting />}
            >
              <Routes>
                {routes.map((route) => {
                  const Component = route.component

                  return (
                    <Route
                      path={route.path}
                      key={route.path}
                      element={<Component />}
                    >
                      {!!route.nestedRoutes && route.nestedRoutes.map((route, index) => {
                        const Component = route.component

                        return (
                          <Route
                            index={route.isIndex}
                            path={route.path}
                            key={route.path || index}
                            element={<Component />}
                          />
                        )}
                      )}
                    </Route>
                  )})}

                <Route
                  path="*"
                  element={(
                    <Navigate
                      to="/drive"
                      replace
                    />
                  )}
                />
              </Routes>

              <AddToAlbum />

              <SharingModal />

              <FamilyInviteModal />

              <PhotoPreviewModal />

              <FilesPreviewModal />

              <PhotoPreviewFlashbackModal />

              <PhotoPreviewTransformerModal />
            </ConnectedLayout>
          )}
        />
      )}

      <Route
        path="*"
        element={<div />}
      />
    </Routes>
  )
}
