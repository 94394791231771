import React, { useEffect } from "react"

import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { PeopleCard, PeopleGrid } from "@cloudike/web_ui_components"
import { useAppDispatch } from "store"
import { useTranslation } from "react-i18next"


import { AlbumsPlacesPageTopbar } from "../../../features/albums/albums-places/AlbumsPlacesTopbar"
import { albumsPersonActions, fetchPersonAlbumsThunk } from "../../../features/albums/albums-person/albumPersonSlice"
import { useIsWithCountVisible } from "../../../features/albums/albums-person/useIsWithCountVisible"
import {
  getAlbumsPersonItems,
  getAlbumsPersonWithNoneZeroContent
} from "../../../features/albums/albums-person/selectors"
import { getPreviewAlbumLink } from "../../../utils/getPreviewAlbumLink"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { analytics, ANALYTICS_EVENTS } from "../../../features/common/analytics"

export const AlbumsPeoplePage = ({ type }) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isWithCountsVisible = useIsWithCountVisible()
  const allAlbumsPerson = getAlbumsPersonItems()
  const albumsPerson = getAlbumsPersonWithNoneZeroContent()
  // total items in all albums
  const totalItems = allAlbumsPerson.reduce((acc, item) => {
    return acc + item.items_count
  }, 0)
  // average items in all albums
  const averageItems = (totalItems / allAlbumsPerson.length).toFixed(2)

  useEffect(() => {
    dispatch(albumsPersonActions.setCurrentAlbumsType(type))
    dispatch(fetchPersonAlbumsThunk({}))
  }, [])

  const handleBack = () => {
    window.history.back()
  }

  const handleClickAlbum = (id: string) => {
    if (type === SDK_TYPES.DEFAULT) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_FACES_VIEW)
    }

    const albumPath = type === SDK_TYPES.DEFAULT ? `/photos/albums/people/${id}` : `/family/photos/albums/people/${id}`
    navigate(albumPath)
  }

  return (
    <>
      <AlbumsPlacesPageTopbar title={t('l_search_people')}
        onBack={handleBack}
      />

      <PeopleGrid>

        {isWithCountsVisible && (
          <CounterBox>
            <div>
              {`Total: ${totalItems}`}
            </div>

            <div>
              {`Avg: ${averageItems}`}
            </div>
          </CounterBox>
        )}

        {albumsPerson.map((item) => {
          return item._embedded && (
            <PeopleCard
              key={item.id}
              id={item.id}
              img={getPreviewAlbumLink(item, 'album')}
              onClick={handleClickAlbum}
              name={item.description}
              itemsCounter={isWithCountsVisible ? item.items_count : undefined}
            />
          )
        })}
      </PeopleGrid>
    </>

  )
}

const CounterBox = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: 20px;
    color: var(--accent-normal);
    right: 0;
    top: 0; 
`
