import { LOADING_STATUSES } from "constants/loadingStatuses"
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React, { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import {
  insertElementsToTimelineThunk,
  loadTimelineItemsFirstTimeThunk,
  subscribeTimelineToWSThunk,
  timelineActions,
  unsubscribeTimelineFromWSThunk
} from "features/photo/timeline/timelineSlice"
import { Timeline } from "features/photo/timeline/Timeline"
import { getTimelineLoadingStatusSelector, getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "features/photo/timeline/selectors"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { addItemsToAlbumThunk, albumActions, fetchAlbumDataThunk } from "features/albums/album/albumSlice"
import { getAlbumDataSelector } from "features/albums/album/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { PageTimelineSkeleton, PrimaryButton, SpriteIcon } from "@cloudike/web_ui_components"
import { useMobileDetection } from "features/common/hooks"
import { subscribeFamilyToWSThunk, unsubscribeFamilyFromWSThunk } from "features/family/familySlice"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import _ from "lodash"
import classNames from "classnames"

import { uploadAlbumItemsThunk, UPLOADER_LIST_TYPES } from "../../../features/common/files-uploading/filesUploadingSlice"
import { getUploadingFilesSelector } from "../../../features/common/files-uploading/selectors"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { appActions } from "../../../store/app"
import { getContentTypesUploadSelector } from "../../../features/common/selectors"
import { Tabs } from "../../../ui/Tabs"
import { analytics, ANALYTICS_EVENTS } from "../../../features/common/analytics"

interface AddingPhotosToAlbumPageProps {
  type: SDK_TYPES
}

const AddingPhotosToAlbumPage: React.FC<AddingPhotosToAlbumPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams<{ id?: string, redirect_to?: string }>()
  const [searchParams] = useSearchParams()

  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const albumData = getAlbumDataSelector()
  const status = getTimelineLoadingStatusSelector()
  const contentTypesUpload = getContentTypesUploadSelector()

  const isMobile = useMobileDetection()
  const redirectToUrl = searchParams.get(REDIRECT_TO)

  const tabs = [
    {
      label: t('a_common_personalTimeline'),
      active: type === SDK_TYPES.DEFAULT,
      value: SDK_TYPES.DEFAULT,
    },
    {
      label: t('a_common_familyTimeline'),
      active: type === SDK_TYPES.FAMILY,
      value: SDK_TYPES.FAMILY,
    }
  ]

  usePageTitle('l_common_addPhotoToAlbum')

  useEffect(() => {
    dispatch(appActions.toggleLayoutSidebarsVisibility(false))
    dispatch(albumActions.setCurrentAlbumId(id))
    if (type === SDK_TYPES.FAMILY) dispatch(albumActions.setCurrentAlbumsType(type))
    dispatch(fetchAlbumDataThunk())

    dispatch(timelineActions.setCurrentTimelineType(type))
    dispatch(loadTimelineItemsFirstTimeThunk())
    dispatch(subscribeTimelineToWSThunk())

    if (type === SDK_TYPES.FAMILY) {
      dispatch(subscribeFamilyToWSThunk({ navigate }))
    }

    return () => {
      dispatch(appActions.toggleLayoutSidebarsVisibility(true))
      dispatch(albumActions.resetState())
      dispatch(timelineActions.resetState())
      dispatch(unsubscribeTimelineFromWSThunk())

      if (type === SDK_TYPES.FAMILY) {
        dispatch(unsubscribeFamilyFromWSThunk())
      }
    }
  }, [])

  const uploadingFiles = getUploadingFilesSelector()

  useEffect(() => {
    if (type === SDK_TYPES.FAMILY) {
      const uploadedFamilyCloudFiles = uploadingFiles.filter(item =>
        item.state.isCompleted &&
        [UPLOADER_LIST_TYPES.FAMILY_TIMELINE, UPLOADER_LIST_TYPES.FAMILY_TIMELINE_COLLABORATOR].includes(item.uploadingSourceType)
        && item.uploadedItem)
        .map(item => item.uploadedItem)

      if (uploadedFamilyCloudFiles.length) {
        dispatch(insertElementsToTimelineThunk({ items: uploadedFamilyCloudFiles }))
      }
    }
  }, [uploadingFiles])


  const handleCancelSelection = useCallback(() => {
    dispatch(timelineActions.unselectAll())
  }, [])

  const handleBack = () => {
    // if 'redirect_to' is in searchParams, go back to this url
    if (!_.isNil(redirectToUrl)) {
      navigate(redirectToUrl)
      return
    }

    if (type === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums/${albumData.id}`)
    }

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/${albumData.id}`)
    }
  }

  const handleAddPhotos = useCallback(() => {
    if (!redirectToUrl) {
      analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_INSIDE_ADD_TO_ALBUM)
    } else {
      if (type === SDK_TYPES.DEFAULT) {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_INSIDE_ADD_TO_ALBUM_PERSONAL)
      }

      if (type === SDK_TYPES.FAMILY) {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_INSIDE_ADD_TO_ALBUM_FAMILY)
      }
    }

    dispatch(addItemsToAlbumThunk({
      items: selectedItems,
      callback: handleBack,
      t
    }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }, [searchParams, selectedItems])

  const getPageTitle = useCallback(() => {
    let title = `${t(isMobile ? 'l_albums_addToAlbum' : 'a_common_addPhotosToAlbum')} ${albumData?.description || ''}`
    const redirectUrl = searchParams.get(REDIRECT_TO)

    if (!_.isNil(redirectUrl)) {
      if (location.pathname.startsWith('/family')) {
        title = `${t(isMobile ? 'l_family_timeline' : 'l_family_timeline')}`
      } else {
        title = `${t(isMobile ? 'l_common_personalTimeline' : 'l_common_personalTimeline')}`
      }
    }

    return title
  }, [searchParams])

  const handleUploadPhotos = (event) => {
    const files = event.target.files

    if (files.length > 0) {
      if (!redirectToUrl) {
        analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_INSIDE_ADD_FROM_DEVICE)
      } else {
        analytics.push(ANALYTICS_EVENTS.WEB_FC_INSIDE_ADD_TO_ALBUM_DEVICE)
      }

      dispatch(uploadAlbumItemsThunk({ files, type: redirectToUrl ? SDK_TYPES.FAMILY : SDK_TYPES.DEFAULT, id: albumData.id }))
      event.target.value = ''

      handleBack()
    }
  }

  const handleChangeTab = (tab) => {
    if (tab.value === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums/${id}/add?${REDIRECT_TO}=${redirectToUrl}`, { replace: true })
    } else {
      navigate(`/family/photos/albums/${id}/add?${REDIRECT_TO}=${redirectToUrl}`, { replace: true })
    }
  }

  return (
    <>
      <SPageTopbarBox
        title={getPageTitle()}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
        selectedItemsCount={selectedItemsCount}
        onCancelSelection={handleCancelSelection}
        description={!isMobile ? t('l_common_selectPhotosToCopy') : ''}
        isAcceptBtnActive={false}
        onBack={handleBack}
        isMobile={isMobile}
        isDescriptionShownIfSelectedFileExists
        rightContent={(
          <SRightTopbarContent>
            <SUploadButton
              htmlFor="timeline-upload"
            >
              {t('a_common_addFromDevice')}

              <SHiddenFilesInput
                id="timeline-upload"
                onChange={handleUploadPhotos}
                type="file"
                multiple
                accept={contentTypesUpload}
              />
            </SUploadButton>


            <SUploadBtnMobile htmlFor="timeline-upload-mobile">
              <SpriteIcon iconName="add_photo_alternate" />

              <SHiddenFilesInput
                id="timeline-upload-mobile"
                onChange={handleUploadPhotos}
                type="file"
                multiple
                accept={contentTypesUpload}
              />
            </SUploadBtnMobile>

            <SPrimaryButton actionName={t('a_common_done')}
              disabled={!selectedItemsCount}
              onAction={handleAddPhotos}
            />

            <SDoneBtnMobile
              className={classNames({ disabled: !selectedItemsCount })}
              onClick={handleAddPhotos}
            >
              <SpriteIcon iconName="check_24px" />
            </SDoneBtnMobile>
          </SRightTopbarContent>
        )}
      />

      {
        status === LOADING_STATUSES.LOADING &&
        <StyledSkeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING && (
          <>
            {!!redirectToUrl && (
              <STabs
                items={tabs}
                onChangeTab={handleChangeTab}
              />
            )
            }

            <STimeline
              haveCloudButtons={!!redirectToUrl}
              type={type}
              withDisabledPreview
              withControls={false}
              className={classNames({ 'with-margin-top': !redirectToUrl })}
            />
          </>
        )
      }
    </>
  )
}

const StyledSkeleton = styled(PageTimelineSkeleton)`
  padding: 0;
`

const SPageTopbarBox = styled(PageTopbar)`
  background: var(--background-primary);
  width: 100%;
  z-index: 15;
  width: 100% !important;
  align-items: flex-start;
  padding-bottom: 20px;

  h2 {
    font-size: 18px;
  }

  .page-topbar-cross-btn {
    margin-right: 12px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    width: calc(100% - 330px) !important;

    h2 {
      font-size: 24px;
    }

    .page-topbar-cross-btn {
      margin-right: 16px;
    }
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    width: calc(100% - 16px) !important;
  }
`

const STimeline = styled(Timeline)`
`

const SRightTopbarContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    padding-right: 0;
  }
`

const SPrimaryButton = styled(PrimaryButton)`
  margin-left: 16px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SUploadButton = styled.label`
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--text-primary);
  padding: 0 32px;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    width: unset;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SHiddenFilesInput = styled.input`
  display: none;
`

const SUploadBtnMobile = styled.label`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 50%;
  margin-right: 17px;
  color: var(--text-secondary);
  
  &.disabled {
    background-color: #f5f7f9;
    color: var(--text-secondary);
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const SDoneBtnMobile = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  border-radius: 50%;
  margin-right: 17px;
  color: var(--background-tertiary);
  background: var(--button-primary-default);
  
  &.disabled {
    background-color: var(--button-primary-disabled);
    color: var(--text-secondary);
    pointer-events: none;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const STabs = styled(Tabs)`
`

export default AddingPhotosToAlbumPage
