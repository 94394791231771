import { useCallback, useEffect, useState } from 'react'

import styled from 'styled-components'
import { getUserDataSelector } from 'features/user/selectors'
import { useAppDispatch } from "store"
import _ from "lodash"
import { FilesTableTypes, SelectType } from "@cloudike/web_ui_components"
import { useTranslation } from "react-i18next"

import {
  getAllFamilyMembersSelector,
  getFamilyDataSelector,
  getFamilyMemberCheckboxVisibilitySelector,
  getFamilyMemberRenamingIdSelector,
  getFamilyMemberSelectedSelector,
  getFamilyMemberSelectTypeSelector,
  getFamilyMembersSortSelector,
  hasFamilyMembersFieldSelector
} from './selectors'
import { familyActions } from './familySlice'
import { FamilyMembersList } from "./FamilyMembersList"
import { useGetFamilyMembersTableColumns } from "./useGetFamilyMembersTableColumns"


export const ManageFamilyMembers = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const userData = getUserDataSelector()
  const members = getAllFamilyMembersSelector()
  const hasPhoneOrEmail = hasFamilyMembersFieldSelector('phone_or_email')

  const familyData = getFamilyDataSelector()
  const membersTableColumns = hasPhoneOrEmail ? ['name', 'phone_or_email'] : ['name']
  const isUserOwner = familyData?.owner_id === userData.id

  const [withPressedCtrl, setWithPressedCtrl] = useState(false)
  const [withPressedShift, setWithPressedShift] = useState(false)

  const sortState = getFamilyMembersSortSelector()
  const selectType = getFamilyMemberSelectTypeSelector()
  const checkboxVisibility = getFamilyMemberCheckboxVisibilitySelector()
  const selectedFilesIds = getFamilyMemberSelectedSelector()
  const renamingFileId = getFamilyMemberRenamingIdSelector()

  const selectStartListener = useCallback((e) => {
    e.preventDefault()

    return false
  }, [])

  function downHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(true)
    }

    if (key === 'Shift') {
      setWithPressedShift(true)

      document.addEventListener('selectstart', selectStartListener)
    }
  }

  function upHandler({ key }) {
    if (key === 'Meta' || key === 'Control') {
      setWithPressedCtrl(false)
    }

    if (key === 'Shift') {
      setWithPressedShift(false)

      document.removeEventListener('selectstart', selectStartListener)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  const [arrColumns, handleSortByColumn] = useGetFamilyMembersTableColumns(
    onChangeSorting,
    sortState,
    membersTableColumns
  )

  function onChangeSorting(sortState) {
    dispatch(familyActions.setSort(sortState))
  }

  function handleSelectItem(node, items) {
    if (withPressedShift) {
      dispatch(familyActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    if (withPressedCtrl) {
      dispatch(familyActions.selectItemWithPressedCtrl({ id: node.id, items }))

      return
    }

    dispatch(familyActions.selectItem({ id: node.id, items }))

  }

  function handleElementsSelection(node, items) {

    if (withPressedShift) {
      dispatch(familyActions.selectItemWithPressedShift({ id: node.id, items }))

      return
    }

    dispatch(familyActions.selectItemWithPressedCtrl({ id: node.id, items }))
  }

  const handleCancelSelection = () => {
    dispatch(familyActions.unselectAll())
  }

  const handleSelectAll = (items) => {
    const allSelectableItems = items.filter((item) => item.isSelectable)
    dispatch(familyActions.selectAll(allSelectableItems))
  }

  function handleClickAllCheckboxes(allItems) {
    if (selectType === SelectType.ALL) {
      handleCancelSelection()
    } else {
      handleSelectAll(allItems)
    }
  }

  const membersToItems = members.map((member) => {
    const isMemberFamilyOwner = member.role === 'owner'
    const isMemberYou = Number(member.id) === Number(userData.id)

    const memberName = !isMemberFamilyOwner ? isMemberYou ? `${member.name} (${t('l_common_you')})` : member.name : isUserOwner
      ? `${member.name} (${t('l_common_ownerYou')})` : `${member.name} (${t('l_common_owner')})`
    return {
      ...member,
      id: member.id,
      name: memberName,
    }
  })

  return (
    <MembersBox className={className}>
      {membersToItems.length > 0 && (
        <FamilyMembersList
          items={membersToItems}
          columns={arrColumns}
          selectType={selectType}
          checkboxVisibility={checkboxVisibility}
          onRenameItem={_.noop()}
          onSortingChange={handleSortByColumn}
          onToggleItem={handleSelectItem}
          onChangeFolder={_.noop()}
          onClickCheckbox={handleElementsSelection}
          onRightClick={_.noop()}
          onEditPublicLink={_.noop()}
          selectedFilesIds={selectedFilesIds}
          renamingFileId={renamingFileId}
          type={FilesTableTypes.trash}
          withoutContextMenu={true}
          onClickAllCheckbox={handleClickAllCheckboxes}
        />
      )}
    </MembersBox>
  )
}

const MembersBox = styled.ul`
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
`
