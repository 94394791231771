import { useLayoutEffect, useState } from "react"

export const useElectronDetection = () => {
  const [isElectronAvailable, setIsElectronAvailable] = useState(false)
  useLayoutEffect(() => {
    try {
      setIsElectronAvailable(!!window.electron)
    } catch (e) {}
  },[])
  return isElectronAvailable
}
