import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { Album } from "features/albums/album/Album"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  albumActions,
  fetchAlbumDataThunk,
  loadAlbumItemsFirstTimeThunk,
  subscribeAlbumToWSThunk,
  unsubscribeAlbumFromWSThunk
} from "features/albums/album/albumSlice"
import {
  getAlbumDataLoadingStatusSelector,
  getAlbumDataSelector,
  getAlbumItemsLoadingStatusSelector,
  getAlbumSelectedItemsCountSelector,
  getAlbumTotalItemsCountSelector,
  getIsAlbumNameEditingSelector
} from "features/albums/album/selectors"
import { SDK_TYPES } from "sdk/sdkConstants"
import { AlbumPageTopbar, PhotosGrid } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { useMobileDetection } from "features/common/hooks"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { photoPreviewActions } from "../../../../features/photo/photo-preview/photoPreviewSlice"

const AlbumPlacePage = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { id } = useParams<{ id?: string }>()

  const albumDataLoadingStatus = getAlbumDataLoadingStatusSelector()
  const selectedItemsCount = getAlbumSelectedItemsCountSelector()
  const albumData = getAlbumDataSelector()
  const isAlbumNameEditing = getIsAlbumNameEditingSelector()
  const status = getAlbumItemsLoadingStatusSelector()
  const totalItemsCount = getAlbumTotalItemsCountSelector()

  const isMobile = useMobileDetection()

  usePageTitle('l_family_albumDetails')

  useEffect(() => {
    dispatch(albumActions.setCurrentAlbumId(id))
    dispatch(albumActions.setCurrentAlbumsType(type))
    dispatch(subscribeAlbumToWSThunk())
    dispatch(fetchAlbumDataThunk())
    dispatch(loadAlbumItemsFirstTimeThunk())

    return () => {
      dispatch(unsubscribeAlbumFromWSThunk())
      dispatch(albumActions.resetState())
      dispatch(photoPreviewActions.resetState())
    }
  }, [id])

  useEffect(() => {
    if (status === LOADING_STATUSES.FAILED) {
      type === SDK_TYPES.FAMILY ? navigate('/family/photos/albums') : navigate('/photos/albums')
    }
  }, [status])

  const handleBack = () => {
    const from = (location?.state as any)?.from

    if (from) {
      navigate(from, { replace: true })

      return
    }

    const backTo = new URLSearchParams(location.search).get('backTo')
    const link = type === SDK_TYPES.DEFAULT ? (backTo === 'albums' ? '/photos/albums' : '/photos/albums/places/') : (backTo === 'albums' ? '/family/photos/albums' : '/family/photos/albums/places/')

    navigate(link)
  }

  const getTexts = () => ({
    dates: '',
    photosCount: !!totalItemsCount ? t('l_common_countElements', { number: totalItemsCount }) : ''
  })

  const handleCancelSelection = useCallback(() => {
    dispatch(albumActions.unselectAll())
  }, [])

  return (
    <>
      {
        albumDataLoadingStatus !== LOADING_STATUSES.LOADING && (
          <AlbumPageTopbar
            title={albumData.description}
            selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
            selectedItemsCount={selectedItemsCount}
            placeholder={isMobile ? t('l_albums_enterAlbumNameShort') : t('l_albums_enterAlbumName')}
            isEditing={isAlbumNameEditing}
            onCancelSelection={handleCancelSelection}
            onStartEdit={() => {
              return
            }}
            onFinishEdit={() => {
              return
            }}
            onBack={handleBack}
            texts={getTexts()}
          />
        )}

      {
        status !== LOADING_STATUSES.LOADING && albumDataLoadingStatus !== LOADING_STATUSES.LOADING && (
          <Album
            id={id}
            type={type}
            isPlace
          />
        )
      }

      {
        (status === LOADING_STATUSES.LOADING || albumDataLoadingStatus === LOADING_STATUSES.LOADING) &&
        <Skeleton />
      }

      {selectedItemsCount !== 0 && <MobileToolbarMenuToggle />}
    </>
  )
}

const Skeleton = () => {
  return (
    <>
      <PageTopbarSkeleton />

      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

const PageTopbarSkeleton = styled.div`
  margin-top: 20px;
  margin-bottom: 18px;
  background: var(--background-tertiary);
  height: 58px;
  width: 300px;
`

export default AlbumPlacePage
