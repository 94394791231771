import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { familyMemberSelectors } from "./familySlice"

export const getFamilyLoadingStatusSelector = () => useAppSelector(state =>
  state.family.status
)

export const getFamilyDataSelector = () => useAppSelector(state =>
  state.family.familyData
)

export const getFamilyMembersSelector = () => useAppSelector(state =>
  familyMemberSelectors.selectAll(state.family)
)

export const getAllFamilyMembersSelector = () => useAppSelector(state => familyMemberSelectors.selectAll(state.family))

export const getFamilyMembersSortSelector = () => useAppSelector(state =>
  state.family.sort
)

export const getFamilyMemberSelectTypeSelector = () => useAppSelector(state =>
  state.family.selectType
)

export const getFamilyMemberCheckboxVisibilitySelector = () => useAppSelector(state =>
  state.family.checkboxVisibility
)

const getFamilyMembersSelectedIdsRawSelector = state =>
  state.family.selectedItemsIds

export const getFamilyMemberSelectedSelector = () => useAppSelector(getFamilyMembersSelectedIdsRawSelector)

export const getFamilyInviteModalIsOpenedSelector = () => useAppSelector(state =>
  state.family.isFamilyInviteModalOpened
)

export const getFamilyMemberRenamingIdSelector = () => useAppSelector(state =>
  state.family.renamingItemId
)

export const getUserHasTimelineItemsSelector = () => useAppSelector(state =>
  state.family.userHasTimelineItems
)

export const getIsOnboardingActiveSelector = () => useAppSelector(state =>
  state.family.isOnboardingActive
)
export const getOnboardingStepSelector = () => useAppSelector(state =>
  state.family.onboardingStep
)

export const getSelectedMembersRawSelector = createSelector(
  state => familyMemberSelectors.selectAll(state.family),
  getFamilyMembersSelectedIdsRawSelector,
  (members, selectedItemsIds) => {
    return members.filter(member => selectedItemsIds.includes(member.id))
  }
)

const hasFamilyMembersFieldRawSelector = createSelector(
  state => familyMemberSelectors.selectAll(state.family),
  (_, fieldName) => fieldName,
  (members, fieldName) => {
    return members.some(member => member[fieldName])
  }
)

export const hasFamilyMembersFieldSelector = (fieldName: string) => useAppSelector(state => hasFamilyMembersFieldRawSelector(state, fieldName))

export const getSelectedMembersSelector = () => useAppSelector(getSelectedMembersRawSelector)
