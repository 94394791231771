import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { AlbumCard, AlbumsGrid, PageSpiner } from '@cloudike/web_ui_components'
import { getAlbumDateStr } from 'features/albums/utils/albumDate'
import { IExtendedAlbumSchema } from 'features/albums/albums-list/albumsSlice'
import { useLocation, useNavigate } from 'react-router-dom'

import { getPublicLinksItemsSelector, getPublicLinksTotalItemsCountSelector, getPublicLinksSelectedItemsIdsSelector } from './selectors'
import { EmptyPublicLinksPlaceholder } from './EmptyPublicLinksPlaceholder'
import { loadMorePublicLinksItemsThunk, publicLinksActions, PUBLIC_LINKS_TYPES } from './publicLinksSlice'

interface PublicAlbumsProps {
  className?: string,
  type: PUBLIC_LINKS_TYPES
}

export const PublicAlbums: React.FC<PublicAlbumsProps> = ({ className = '', type }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const items = getPublicLinksItemsSelector()
  const totalItemsCount = getPublicLinksTotalItemsCountSelector()
  const selectedItemsIds = getPublicLinksSelectedItemsIdsSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMorePublicLinksItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items])

  const handleItemSelection = useCallback((id) => {
    dispatch(publicLinksActions.selectItem(id))
  }, [])

  const handleCardClick = (album: IExtendedAlbumSchema) => {
    const link = type === PUBLIC_LINKS_TYPES.ALBUMS ? `/photos/albums/${album.id}` : `/family/photos/albums/${album.id}`

    navigate(link, { state: { from: location.pathname } })
  }

  if (!items.length) {
    return (
      <PublicAlbumsBox
        className={className}
      >
        <EmptyPublicLinksPlaceholder
          texts={{
            title: type === PUBLIC_LINKS_TYPES.ALBUMS ? t('l_more_noSharedAlbumsTitle') : t('l_more_noFamilySharedAlbumsTitle'),
            description: PUBLIC_LINKS_TYPES.ALBUMS ? t('l_more_noSharedAlbumsMessage') : t('l_more_noFamilySharedAlbumsMessage')
          }}
        />
      </PublicAlbumsBox>
    )
  }

  return (
    <PublicAlbumsBox
      className={className}
    >
      <AlbumItemsBox>
        <AlbumsGrid>
          {items.map(album => (
            <AlbumCard
              key={album.id}
              id={album.id}
              albumName={album.description || t('l_common_untitledAlbum')}
              albumPhotoCount={t('l_common_countElements', { number: album.live_items_count })}
              description={album.description}
              onSelect={handleItemSelection}
              isSelected={selectedItemsIds.includes(album.id)}
              imgUrl={(album?._embedded?.cover_item?._links as any)?.image_middle?.href}
              albumModified={getAlbumDateStr(album)}
              onClick={() => handleCardClick(album)}
            />
          ))}
        </AlbumsGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </AlbumItemsBox>
    </PublicAlbumsBox>
  )
}

const PublicAlbumsBox = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 40px;
  height: calc(100% - 143px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  
  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const AlbumItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`
