import { useEffect, useState } from 'react'

import { IFilesTableHeaderColumn, OrderType, } from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const COLUMNS = {
  name: {
    label: 'l_common_name',
    translationKey: 'l_common_name',
    sortName: 'name',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  },
  modified: {
    label: 'l_common_modified',
    translationKey: 'l_common_modified',
    sortName: 'updated',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  },
  deleted: {
    label: 'l_common_deleted',
    translationKey: 'l_common_deleted',
    sortName: 'updated',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  },
  size: {
    label: 'l_common_size',
    translationKey: 'l_common_size',
    sortName: 'file_info.size',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  },
  access: {
    label: 'l_common_access',
    translationKey: 'l_common_access',
    sortName: 'is_shared',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  },
  phone_or_email: {
    label: 'l_common_email',
    translationKey: 'l_common_email',
    sortName: 'phone_or_email',
    order: OrderType.ASK,
    size: 200,
    isIconVisible: false,
  }
}

export const useGetFilesTableColumns = (onChangeSorting, sortState, columns = ['name', 'modified', 'size', 'access']) => {
  const { t } = useTranslation()
  const [arrColumns, setArrColumns] = useState([])

  const startArrColumns = columns.map(name => COLUMNS[name])

  useEffect(() => {
    setArrColumns(createArrColumns(startArrColumns))
  }, [])

  function createArrColumns(startArrColumns) {
    return _.map(startArrColumns, (item) => {
      item.label = t(item.translationKey)
      if (item.sortName === sortState.by) {
        item.order = sortState.direction
        item.isIconVisible = true
      }

      return item
    })
  }

  const handleSortByColumn = (column: IFilesTableHeaderColumn) => {
    const newArrColumns = arrColumns.map((item) => {
      if (item.sortName === column.sortName) {
        item.order =
          item.order === OrderType.ASK ? OrderType.DESK : OrderType.ASK
        item.isIconVisible = true
        const newSortState = { direction: item.order, by: column.sortName }
        onChangeSorting(newSortState)
      } else {
        item.isIconVisible = false
      }

      return item
    })

    setArrColumns(newArrColumns)
  }

  return [arrColumns, handleSortByColumn]
}
