import React from 'react'

import { getUserDataSelector } from 'features/user/selectors'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppDispatch } from "store"

import { getFamilyMembersSelector } from './selectors'
import { familyActions } from './familySlice'
import { EmptyFamilyMembersPlaceholder } from "./EmptyFamilyMembersPlaceholder"

export const FamilyInvite = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const userData = getUserDataSelector()
  const members = getFamilyMembersSelector()
  const isOwner = userData?.is_owner_family

  const emptyFamilyMembersPlaceholderTexts = {
    title: t('l_familycloud_membersEmptyTitle'),
    description: t('l_familycloud_membersEmptyMessage'),
    inviteBtn: t('a_common_invite')
  }

  const handleOpenInviteModal = () => {
    dispatch(familyActions.toggleFamilyinviteModal(true))
  }

  return (
    <FamilyInviteBox className={className}>
      {members && members.length === 1 && (
        <EmptyFamilyMembersPlaceholder
          texts={emptyFamilyMembersPlaceholderTexts}
          onInviteFamilyMember={handleOpenInviteModal}
        />
      )}

    </FamilyInviteBox>
  )
}

const FamilyInviteBox = styled.div`
`
