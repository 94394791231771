import styled, { StyledComponent } from "@emotion/styled"
import { SpriteIcon, STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { Spinner } from "../../../ui"

export const MainContent: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0;
  position: relative;
  user-select: none;
  z-index: 0;
  align-items: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding: 0 24px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) and (min-height: 700px) {
    height: calc(100vh - 120px);
  }
`

export const ArrowBox: StyledComponent<JSX.IntrinsicElements['button']> = styled.button`
  display: flex;
  border: none;
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: #232323;
  align-items: center;
  justify-content: center;

  &.rotated {
    transform: rotate(180deg);
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    &:hover {
      background-color: #717171;
    }
  }
`

export const ArrowContainer: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    position: absolute;
    height: fit-content;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);

    &.right {
      left: unset;
      right: 20px;
    }
  }
`

export const ArrowIcon = styled(SpriteIcon)`
  && {
    color: #ffffff;
  }
`

export const BottomRow: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  user-select: none;
  position: absolute;
  top: calc(100% - 60px);
  left: 0;
`

export const FileIcon = styled(SpriteIcon)`
  && {
    width: 56px;
    height: 56px;
    color: white;
  }
`

export const FilePreview: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`

export const Iframe: StyledComponent<JSX.IntrinsicElements['iframe']> = styled.iframe`
  display: block;
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
  overflow-y: scroll;
`

export const FilePreviewIconBox: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 8%);
  border-radius: 4px;
  background-color: #ddd;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;  
`
export const ImageBox: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 0 32px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding: 0;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    position: relative;
  }
`

export const Img: StyledComponent<any> = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  user-select: none;
    
  &.not-loaded {
    display: none;
  }
`

export const PreviewModalBox: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 17;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`

export const SHiddenImg = styled.img`
  display: none;
`

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
`

export const StyledVideo: StyledComponent<JSX.IntrinsicElements['video']> = styled.video`
  display: flex;
  padding: 32px 0;
  max-height: 100%;
  max-width: 100%;
  min-width: 100vw;
  min-height: calc(100vw / 16 * 9 + 70px);
  height: auto;
  user-select: none;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 75vw;
    min-width: 75vw;
    min-height: min(calc(75vw / 16 * 9 + 70px), 90vh);
  }
  
  &.not-loaded {
    &::-webkit-media-controls {
      visibility: hidden;
    }

    &::-webkit-media-controls-enclosure {
      visibility: visible;
    }
  }
`

export const TopRow: StyledComponent<JSX.IntrinsicElements['div']> = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
