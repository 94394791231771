import React, { FC,  useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { SpriteIcon } from "@cloudike/web_ui_components"
import classNames from "classnames"

import { useOnClickOutside } from "../features/common/hooks/useOnClickOutside"

import { PositionInPortal } from "./PositioninPortal"

type SelectOptionValue = string | number

export type SelectOption = {
  value: SelectOptionValue,
  label: string,
  icon?: string
}

interface FilterSelectProps {
  options?: SelectOption[],
  value: SelectOption,
  className?: string,
  onSelect: (option: SelectOption) => void
}

export const FilterSelect: FC<FilterSelectProps> = ({ options = [], value, onSelect, className = '' }) => {
  const [isDropdownOpened, toggleDropdown] = useState<boolean>(false)

  const parentRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleSelectClick = () => {
    toggleDropdown(state => !state)
  }

  const handleOptionClick = (option: SelectOption) => {
    onSelect(option)
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        toggleDropdown(false)
      }
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  useOnClickOutside(parentRef, () => {
    toggleDropdown(false)
  }, [dropdownRef])

  return (
    <SSelectBox className={classNames(className, { active: isDropdownOpened })}
      ref={parentRef}
      onClick={handleSelectClick}
    >
      {!!value.icon && <SOptionIcon iconName={value.icon} />}

      {value.label}

      <SArrowIcon iconName="arrow_right"
        className={classNames({ rotated: isDropdownOpened })}
      />

      {isDropdownOpened && (
        <PositionInPortal parentRef={parentRef}
          open={isDropdownOpened}
          topOffset={4}
        >
          <SDropdownOptionsBox ref={dropdownRef}>
            {
              options.map((option: SelectOption) => (
                <SOptionBox onClick={() => handleOptionClick(option)}
                  key={option.value}
                  className={classNames({ active: option.value === value.value })}
                >
                  {!!option.icon && <SOptionIcon iconName={option.icon} />}

                  <SOptionLabel>
                    {option.label}
                  </SOptionLabel>

                  {option.value === value.value &&
                  <SCheckIcon iconName="check_24px" />
                  }
                </SOptionBox>
              ))
            }
          </SDropdownOptionsBox>
        </PositionInPortal>
      )}
    </SSelectBox>
  )
}

const SSelectBox = styled.div`
  height: 40px;
  width: fit-content;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 12px;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
  
  &.active {
    background: var(--button-secondary-press);
  }
`

const SDropdownOptionsBox = styled.div`
  width: fit-content;
  border: 1px solid var(--divider-primary);
  background: var(--background-primary);
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 8px 0;
`

const SOptionBox = styled.div`
  width: 100%;
  height: 40px;
  padding:  0 16px;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover {
    background: var(--background-secondary);
  }
  
  &.active {
    background: var(--background-secondary);
  }
`

const SOptionIcon = styled(SpriteIcon)`
  margin-right: 8px;
  color: var(--icon-primary);
  width: 24px;
  height: 24px;
`

const SArrowIcon = styled(SpriteIcon)`
  margin-left: 8px;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  color: var(--ink-light);
  
  &.rotated {
    transform: rotate(270deg);
  }
`

const SCheckIcon = styled(SpriteIcon)`
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: var(--icon-primary);
`

const SOptionLabel = styled.span`
  margin-right: 20px;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
