import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate } from "react-router-dom"
import { albumActions, createNewEmptyAlbumThunk } from "features/albums/album/albumSlice"
import { AlbumPageTopbar } from "features/albums/album/AlbumPageTopbar"
import { EmptyAlbumPlaceholder } from "features/albums/album/EmptyAlbumPlaceholder"
import { SDK_TYPES } from "sdk/sdkConstants"
import { subscribeFamilyToWSThunk, unsubscribeFamilyFromWSThunk } from "features/family/familySlice"
import { usePageTitle } from "features/common/hooks/usePageTitle"

import { useMobileDetection } from "../../../features/common/hooks"
import { getLoadingAlbumSelector } from "../../../features/albums/albums-list/selectors"

interface CreateAlbumPageProps {
  type: SDK_TYPES
}

const CreateAlbumPage: React.FC<CreateAlbumPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isMobile = useMobileDetection()
  const isLoading = getLoadingAlbumSelector()

  usePageTitle('a_common_createAlbum')

  useEffect(() => {
    if (type === SDK_TYPES.FAMILY) {
      dispatch(subscribeFamilyToWSThunk({ navigate }))
    }

    return () => {
      if (type === SDK_TYPES.FAMILY) {
        dispatch(unsubscribeFamilyFromWSThunk())
      }
    }
  }, [])

  const handleStartEditing = () => {
    dispatch(albumActions.toggleAlbumNameEditStatus(true))
  }

  const handleFinishEditing = (name: string) => {
    if (!name || isLoading) {
      return
    }

    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }
    }

    dispatch(createNewEmptyAlbumThunk({ type, name, callback }))
    dispatch(albumActions.toggleAlbumNameEditStatus(false))
  }

  const handleBack = () => {
    if (type === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums`)
    }

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums`)
    }
  }

  const handleAddPhotos = () => {
    if (isLoading) return
    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}/add`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}/add`)
      }
    }

    dispatch(createNewEmptyAlbumThunk({ type, name: '', callback }))
    dispatch(albumActions.toggleAlbumNameEditStatus(false))
  }

  return (
    <>
      <AlbumPageTopbar
        title=""
        placeholder={isMobile ? t('l_albums_enterAlbumNameShort') : t('l_albums_enterAlbumName')}
        isEditing={true}
        onStartEdit={handleStartEditing}
        onFinishEdit={handleFinishEditing}
        onBack={handleBack}
      />

      <EmptyAlbumPlaceholder
        texts={{
          title: t('l_albums_noPhotosTitle'),
          description: t('l_albums_noPhotosMessage'),
          uploadBtn: t('a_common_addPhotosToAlbum')
        }}
        onButtonClick={handleAddPhotos}
      />
    </>
  )
}

export default CreateAlbumPage
