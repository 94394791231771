import { API_UTILS } from "constants/apiUtils"

import i18n from "i18n"
import _ from "lodash"

import { goToSubscriptionsPage } from "./subscriptions"

export const getErrorData = (error) => {
  let config
  let title
  let message

  switch (true) {
  case error.data?.isOffline:
    config = { title: 'l_notification_offlineError' }
    break
  case error.data?.code === 'FileOrFolderMoveInTheSamePlace' && error.data?.item.folder === true:
    message = 'l_notification_moveFolderError'
    title = 'l_notification_folderMoveError'
    config = { message, title }
    break
  case error.data?.code === 'FileOrFolderMoveInTheSamePlace' && error.data?.item.folder === false:
    message = 'l_notification_cantMoveFile'
    title = 'l_notification_samePlaceMove'
    config = { message, title }
    break
  case error.data?.code === 'FileOrFolderCopyToChildSubfolder':
    message = 'l_notification_copyFolderError'
    title = 'l_notification_folderCopyError'
    config = { message, title }
    break
  case error.data?.code === 'FileOrFolderMoveToChildSubfolder':
    message = 'l_notification_moveFolderError'
    title = 'l_notification_folderMoveError'
    config = { message, title }
    break
    // todo SizeQuotaExceededError was removed from web_core@0.1.15 SDK, check if it works
  case error.data?.code === 'SizeQuotaExceededError' || error.data?.code === 'SizeQuotaExceeded':
    const actionType = error.action // API_UTILS.ACTIONS_TYPES
    const itemType = error.type // API_UTILS.ITEM_TYPE
    const isFamilyOwner = error.isFamilyOwner

    const isFolder = () => error.data?.item?.folder

    title = _.isNil(isFamilyOwner) || isFamilyOwner ? 'l_notification_spaceError' : 'l_notification_outOfStorage'

    if (!isFolder()) {
      if (itemType === API_UTILS.ITEM_TYPE.FILE && actionType === API_UTILS.ACTIONS_TYPES.COPY) {
        message = 'l_notification_copyFilesError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.PHOTO && actionType === API_UTILS.ACTIONS_TYPES.COPY) {
        message = 'l_notification_copyPhotosError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.FILE && actionType === API_UTILS.ACTIONS_TYPES.UPLOAD) {
        message = 'l_notification_uploadFilesError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.PHOTO && actionType === API_UTILS.ACTIONS_TYPES.UPLOAD) {
        message = 'l_notification_uploadPhotoError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.PHOTO && actionType === API_UTILS.ACTIONS_TYPES.UPLOAD_TO_FAMILY) {
        message = 'l_notification_uploadPhotoError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.FILE && actionType === API_UTILS.ACTIONS_TYPES.COPY_TO_FAMILY) {
        message = 'l_notification_copyError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.FILE && actionType === API_UTILS.ACTIONS_TYPES.COPY_TO_PERSONAL) {
        message = 'l_notification_copyError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.PHOTO && actionType === API_UTILS.ACTIONS_TYPES.COPY_TO_FAMILY) {
        message = 'l_notification_copyPhotosToFamilyError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.FILE && actionType === API_UTILS.ACTIONS_TYPES.RESTORE) {
        message = 'l_notification_restoreFilesError'
      }
      if (itemType === API_UTILS.ITEM_TYPE.PHOTO && actionType === API_UTILS.ACTIONS_TYPES.RESTORE) {
        message = 'l_notification_restorePhotoError'
      }
      if (actionType === API_UTILS.ACTIONS_TYPES.SYNC_OTHER_CLOUD) {
        message = i18n.t('l_notification_importError', { NAME: error.nameOfCheckingCloud })
      }
    } else {
      if (actionType === API_UTILS.ACTIONS_TYPES.COPY) {
        message = 'l_notification_copyFolderError'
      }
      if (actionType === API_UTILS.ACTIONS_TYPES.RESTORE) {
        message = 'l_notification_restoreFilesError'
      }
    }

    config = {
      delay: null,
      title,
      message,
      typeError: 'SizeQuotaExceeded',
      isPermanent: true,
      callback:  (_.isNil(isFamilyOwner) || isFamilyOwner) && goToSubscriptionsPage
    }
    break

  case error.data?.code === 'PromoCodeNotFound' || error.data?.code === 'PromoCodeDisabled':
    config = {
      title: 'l_notification_invalidPromoCode',
      message: 'l_notification_activateError'
    }
    break
  case error.data?.code === 'InvalidContent':
    config = {
      title: 'l_notification_invalidContent'
    }
    break
  case error.data?.code === 'UnsupportedMediaType':
    config = {
      title: 'l_notification_unsupportedFile'
    }
    break
  case error.data?.code === 'CantCreateTask':
    config = {
      title: 'l_notification_createMigrationFail'
    }
    break
  case error.data?.code === 'UserMemberOfOtherFamily':
    config = {
      title: 'l_common_alertMessage'
    }
    break
  default:
    config = { title: 'l_common_anyError' }
  }

  config.title = i18n.t(config.title)

  if (config.message) {
    config.message = i18n.t(config.message)
  }

  return config
}
