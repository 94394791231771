import { getModalsRootElement } from 'constants/modals'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon
} from '@cloudike/web_ui_components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { SDK_TYPES } from 'sdk/sdkConstants'
import { publicLinkFilesActions, submitPasswordForSharedFiles } from 'features/public-link-files/publicLinkFilesSlice'
import { getFilesPublicLinkPasswordModalStatusSelector } from 'features/public-link-files/selectors'

import { PasswordInput } from "../../ui/PasswordInput"
import { getHighestLevelErrorByFieldName } from "../../utils/utils"
import { useTimer } from "../common/hooks/useTimer"
import { appActions } from "../../store/app"

import { getPublicLinkPasswordModalStatusSelector } from './selectors'
import { publicLinkActions, PublicLinksErrorTypes, submitPasswordForSharedAlbum } from './publicLinkSlice'

interface PublicLinkPasswordModalProps {
  type?: SDK_TYPES
}

const generateErrorStr = ({ minutes, seconds, ms, t }) => {
  if (ms > 0) {
    return t('l_notification_tryAgainIn', { 0: `${minutes}:${seconds}` })
  }

  return ''
}

export const PublicLinkPasswordModal: React.FC<PublicLinkPasswordModalProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const opened = type === SDK_TYPES.FILES ? getFilesPublicLinkPasswordModalStatusSelector() : getPublicLinkPasswordModalStatusSelector()
  const [secondsToRetry, setSecondsToRetry] = useState<number>(0)

  const inputRef = useRef<any>(null)

  const {
    ms,
    isTimerActive,
    startTimer,
    formattedData
  } = useTimer(secondsToRetry * 1000 || 0)

  useEffect(() => {
    if (ms > 0 && !isTimerActive) {
      startTimer()
    }
  }, [isTimerActive, ms])

  const handleClose = () => {
    if (!opened) {
      return
    }

    if (type === SDK_TYPES.FILES) {
      dispatch(publicLinkFilesActions.togglePasswordModal(false))
      dispatch(publicLinkFilesActions.setError(PublicLinksErrorTypes.NO_ACCESS))

      return
    }

    dispatch(publicLinkActions.togglePasswordModal(false))
    dispatch(publicLinkActions.setError(PublicLinksErrorTypes.NO_ACCESS))
  }

  const handleSubmit = (values: any, { setErrors }) => {
    const errorCallback = (error) => {
      const secondsToRetry = getHighestLevelErrorByFieldName(error, 'details')?.retry_after

      if (secondsToRetry) {
        setSecondsToRetry(secondsToRetry)
      } else {
        setErrors({ password: t('l_notification_invalidPassword') })
      }
    }

    if (type === SDK_TYPES.FILES) {
      dispatch(submitPasswordForSharedFiles({ password: values.password, errorCallback }))

      return
    }

    dispatch(submitPasswordForSharedAlbum({ password: values.password, errorCallback }))
  }

  const handleKeyUp = handleSubmit => (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const validation = yup.object().shape({
    password: yup.string().required(t('l_notification_invalidPassword'))
  })

  useEffect(() => {
    if (!opened) {
      return
    }

    inputRef.current?.focus()
  }, [opened])

  const handleInputRef = (ref: HTMLInputElement) => {
    inputRef.current = ref
  }

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validation}
      validateOnChange
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Form>
          <ModalDialog
            setIsModalVisible={handleVisibleModal}
            header={(
              <ModalHeader
                title={t('l_common_passwordProtection')}
              />
            )}
            footer={(
              <ModalFooter
                buttonList={[
                  <SecondaryButton
                    key={'buttonCancel'}
                    actionName={t('a_common_cancel')}
                    onAction={handleClose}
                  />,
                  <StyledPrimaryButton
                    key={'buttonAction'}
                    actionName={t('a_common_ok')}
                    onAction={handleSubmit}
                    disabled={!values.password}
                  />
                ]}
              />
            )}
            parentBlock={getModalsRootElement()}
            isOpened={opened}
            onCloseModal={handleClose}
          >
            <ContentBox>
              <InputLabel>
                {t('l_common_passwordField')}
              </InputLabel>

              <StyledPasswordInput
                placeholder={t('l_common_enterPasswordField')}
                value={values.password}
                onChange={handleChange}
                onKeyUp={handleKeyUp(handleSubmit)}
                name="password"
                inputRef={handleInputRef}
                error={errors.password || generateErrorStr({
                  ms,
                  t,
                  minutes: formattedData.minutes,
                  seconds: formattedData.seconds
                })}
              />
            </ContentBox>

            <EmptyDivider />
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

const ContentBox = styled.div`
  width: calc(100vw - 80px);
  max-width: 460px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 460px;
  }
`

const InputLabel = styled.label`
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--text-primary);
`

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 16px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const EmptyDivider = styled.div`
  height: 10px;
`
