import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { IItemSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumItem'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { getPhotoSdk, getPhotosWS } from 'sdk/photo'
import { IGetEmbeddedItemsSchema } from '@cloudike/web_photos/dist/types/intarfaces/IGetEmbeddedItemsSchema'
import { TrashBinPaginator } from '@cloudike/web_photos/dist/types/lib/Paginator/TrashBinPaginator'
import { RootState } from 'store'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { goToSubscriptionsPage } from 'utils/subscriptions'
import _ from 'lodash'

import { getFilesSdk } from "../../sdk/files"
import { getErrorByFieldName } from "../../utils/utils"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"

import { fetchTrashBinFilesNodesThunk } from './files/trashBinFilesSlice'

export enum TRASH_BIN_TYPES {
  FILES,
  PHOTOS,
  FAMILY_PHOTOS
}

const adapter = createEntityAdapter<IItemSchema>()

export const trashBinSelectors = adapter.getSelectors()

let paginator: TrashBinPaginator | null = null

export const initPaginator = (type: TRASH_BIN_TYPES, pageSize = 20, params: IGetEmbeddedItemsSchema) => {
  if (type === TRASH_BIN_TYPES.PHOTOS) {
    paginator = getPhotoSdk().trashBin.getTrashItemsPaginator(pageSize, params)
  }

  if (type === TRASH_BIN_TYPES.FAMILY_PHOTOS) {
    paginator = getPhotoSdk().familyTrashBin.getTrashItemsPaginator(pageSize, params)
  }

  return paginator
}

const getTrashBinSdkByType = (type: TRASH_BIN_TYPES) => {
  switch (type) {
  case TRASH_BIN_TYPES.PHOTOS:
    return getPhotoSdk().trashBin
  case TRASH_BIN_TYPES.FAMILY_PHOTOS:
    return getPhotoSdk().familyTrashBin
  }
}

const sortByDate = (items: IItemSchema[]) => items.sort((a, b) => b.created_original - a.created_original)

export const subscribeTrashBinToWSThunk = createAsyncThunk(
  'trashBin/subscribeTrashBinToWSThunk',
  async function(_, { dispatch }) {
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE, ({ action, output }) => {
      if (action === 'add_items') {
        const ids = output.map(item => item.detail.item_id)
        dispatch(actions.deleteItems(ids))
        dispatch(actions.unselectItems(ids))

        debouncedCheckTrashBinTotalItemsCounts(dispatch)
      }

      if (action === 'delete_items') {
        dispatch(loadJustUploadedTrashBinItemsThunk())

        debouncedCheckTrashBinTotalItemsCounts(dispatch)
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_TRASH_OPERATION_DONE, ({ action, output }) => {
      if (action === 'delete_items') {
        const ids = output.map(item => item.detail.item_id)
        dispatch(actions.deleteItems(ids))
        dispatch(actions.unselectItems(ids))

        debouncedCheckTrashBinTotalItemsCounts(dispatch)
      }
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.COPIED_INTO_TRASH, () => {
      debouncedCheckTrashBinTotalItemsCounts(dispatch)
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.PHOTOS_ITEMS_MOVED_INTO_TRASH, () => {
      debouncedCheckTrashBinTotalItemsCounts(dispatch)
    })
  }
)

export const unsubscribeTrashBinFromWSThunk = createAsyncThunk(
  'trashBin/unsubscribeTrashBinFromWSThunk',
  async function() {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.PHOTOS_TRASH_OPERATION_DONE)
    photosWs.removeEventListener(WS_EVENTS_NAMES.COPIED_INTO_TRASH)
  }
)

export const checkTrashBinTotalItemsCountsThunk = createAsyncThunk(
  'trashBin/checkTrashBinTotalItemsCountsThunk',
  async function(_, { dispatch, getState }) {
    const trashBinService = getPhotoSdk().trashBin
    const familyTrashBinService = getPhotoSdk().familyTrashBin
    const state = getState() as RootState
    const type = state.trashBin.type
    const user = state.user.userData

    const getPhotosTotalCount = async () => {
      const photosItemsResponse = await trashBinService.getItems({ total_count: true, limit: 1 })

      return Number(photosItemsResponse.headers[TOTAL_COUNT_HEADER])
    }

    const getFamilyPhotosTotalCount = async () => {
      if (!user.family_user_id) {
        return 0
      }

      const familyPhotosItemsResponse = await familyTrashBinService.getItems({ total_count: true, limit: 1 })

      return Number(familyPhotosItemsResponse.headers[TOTAL_COUNT_HEADER])
    }

    const getFilesTotalCount = async () => {
      const sdk = getFilesSdk()

      const response = await sdk.trashBin.getItems({ total_count: true, limit: 1 })

      return parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }

    const photosTotalCount = await getPhotosTotalCount()
    const familyItemsTotalCount = await getFamilyPhotosTotalCount()
    const filesTotalCount = await getFilesTotalCount()

    dispatch(actions.setPhotosCount(photosTotalCount))
    dispatch(actions.setFamilyPhotosCount(familyItemsTotalCount))
    dispatch(actions.setFilesCount(filesTotalCount))

    const totalCount = type === TRASH_BIN_TYPES.PHOTOS ? photosTotalCount : type === TRASH_BIN_TYPES.FAMILY_PHOTOS ? familyItemsTotalCount : filesTotalCount

    dispatch(actions.setTotalCount(totalCount))
  }
)

export const debouncedCheckTrashBinTotalItemsCounts = _.debounce((dispatch) => dispatch(checkTrashBinTotalItemsCountsThunk()), 500)

export const loadTrashBinItemsFirstTimeThunk = createAsyncThunk(
  'trashBin/loadTrashBinItemsFirstTimeThunk',
  async function({ type }: { type: TRASH_BIN_TYPES }, { dispatch }) {
    let totalCount = 0
    let items = []

    if (type === TRASH_BIN_TYPES.FILES) {
      dispatch(fetchTrashBinFilesNodesThunk())
    } else {
      const paginator = initPaginator(type, 20, { total_count: true })
      const response = await paginator.next()

      items = response.data._embedded.items
      totalCount = Number(response.headers[TOTAL_COUNT_HEADER])
    }


    dispatch(actions.setTrashBinType(type))
    dispatch(checkTrashBinTotalItemsCountsThunk())

    return {
      items,
      totalCount
    }
  }
)

export const loadJustUploadedTrashBinItemsThunk = createAsyncThunk(
  'trashBin/loadJustUploadedTrashBinItemsThunk',
  async function(_, { getState, dispatch }) {
    const state = (getState() as RootState).trashBin
    const type = state.type
    const sdk = getTrashBinSdkByType(type)

    const currentItems = trashBinSelectors.selectAll(state)
    const currentItemsIds = currentItems.map(item => item.id)

    const response = await sdk.getItems({ offset: 0, limit: 200, total_count: true })

    const itemsForInsert = response.data._embedded.items.filter(item => !currentItemsIds.includes(item.id))

    dispatch(actions.setAllItems([...itemsForInsert, ...currentItems]))
    dispatch(actions.setTotalCount(Number(response.headers[TOTAL_COUNT_HEADER])))
  }
)

export const loadMoreTrashBinItemsThunk = createAsyncThunk(
  'trashBin/loadMoreTrashBinItemsThunk',
  async function() {
    const response = await paginator.next()

    return response.data._embedded.items
  }
)

export const fullRestoreTrashBinThunk = createAsyncThunk(
  'trashBin/fullRestoreTrashBinThunk',
  async function(__, { getState }) {
    const state = getState() as RootState
    const type = state.trashBin.type
    const isFamilyOwner = state.user.userData.is_owner_family
    const sdk = getTrashBinSdkByType(type)

    try {
      showGlobalProgressLoader()
      await sdk.fullRestore()

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_allItemsRestored')
      })
    } catch (error) {
      const details = getErrorByFieldName(error, 'details')
      const operationsError = details.operations[0]

      if (operationsError?.code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_restorePhotoError'),
          title:  type === TRASH_BIN_TYPES.FAMILY_PHOTOS && !(_.isNil(isFamilyOwner) || isFamilyOwner) ? t('l_notification_outOfStorage') : t('l_notification_spaceError'),
          typeError: 'SizeQuotaExceeded',
          callback: !(type === TRASH_BIN_TYPES.FAMILY_PHOTOS && !(_.isNil(isFamilyOwner) || isFamilyOwner)) && goToSubscriptionsPage
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_canNotComplete'),
          message: t('l_common_wentWrong')
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const emptyTrashBinThunk = createAsyncThunk(
  'trashBin/emptyTrashBinThunk',
  async function(_, { getState }) {
    const type = (getState() as RootState).trashBin.type
    const sdk = getTrashBinSdkByType(type)

    try {
      showGlobalProgressLoader()
      await sdk.emptyTrash()

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_trashEmpty')
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_canNotComplete'),
        message: t('l_common_wentWrong')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteTrashBinItemsThunk = createAsyncThunk(
  'trashBin/deleteTrashBinItemsThunk',
  async function({ items }: { items: IItemSchema[]}, { getState, dispatch }) {
    const type = (getState() as RootState).trashBin.type
    const sdk = getTrashBinSdkByType(type)

    try {
      showGlobalProgressLoader()
      await sdk.deleteItems(items)

      dispatch(actions.decreaseTotalCount(items.length))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsDeletedforWeb', { number: items.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_canNotComplete'),
        message: t('l_common_wentWrong')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const restoreTrashBinSelectedItemsThunk = createAsyncThunk(
  'trashBin/restoreTrashBinSelectedItemsThunk',
  async function({ items } : {items: IItemSchema[]}, { getState }) {
    const state = getState() as RootState
    const type = state.trashBin.type
    const isFamilyOwner = state.user.userData.is_owner_family
    const sdk = getTrashBinSdkByType(type)

    try {
      showGlobalProgressLoader()
      await sdk.restoreItems(items)

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsRestored', { number: items.length })
      })
    } catch (error) {
      const operationsError = error?.cause?.details?.operations[0]

      if (operationsError?.code === 'SizeQuotaExceeded') {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          isPermanent: true,
          message: t('l_notification_restorePhotoError'),
          title:  type === TRASH_BIN_TYPES.FAMILY_PHOTOS && !(_.isNil(isFamilyOwner) || isFamilyOwner) ? t('l_notification_outOfStorage') : t('l_notification_spaceError'),
          typeError: 'SizeQuotaExceeded',
          callback: !(type === TRASH_BIN_TYPES.FAMILY_PHOTOS && !(_.isNil(isFamilyOwner) || isFamilyOwner)) && goToSubscriptionsPage
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_canNotComplete'),
          message: t('l_common_wentWrong')
        })
      }
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const trashBinSlice = createSlice({
  name: 'trashBin',
  initialState: adapter.getInitialState({
    type: null,
    status: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    error: '',
    totalItemsCount: 0,
    selectedItemsIds: [],

    filesCount: 0,
    photosCount: 0,
    familyPhotosCount: 0
  }),
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setTrashBinType: (state, action) => {
      state.type = action.payload
    },
    selectItem: (state, action) => {
      const id = action.payload
      const indexOfItemId = state.selectedItemsIds.indexOf(id)

      if (indexOfItemId === -1) {
        state.selectedItemsIds = [...state.selectedItemsIds, id]
      } else {
        state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
      }
    },
    setAllItems: (state, action) => {
      adapter.setAll(state, sortByDate(action.payload))
    },
    unselectItems: (state, action) => {
      state.selectedItemsIds = state.selectedItemsIds.filter(id => !action.payload.includes(id))
    },
    unselectAll: (state) => {
      state.selectedItemsIds = []
    },
    deleteItems: (state, action: PayloadAction<string[]>) => {
      adapter.removeMany(state, action.payload)
    },
    deleteAllItems: (state) => {
      adapter.removeAll(state)
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      state.selectedItemsIds = []
      adapter.removeAll(state)
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    decreaseTotalCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = state.totalItemsCount - action.payload
    },
    setPhotosCount: (state, action) => {
      state.photosCount = action.payload
    },
    setFamilyPhotosCount: (state, action) => {
      state.familyPhotosCount = action.payload
    },
    setFilesCount: (state, action) => {
      state.filesCount = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadTrashBinItemsFirstTimeThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(loadTrashBinItemsFirstTimeThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalItemsCount = action.payload.totalCount
      })
      .addCase(loadTrashBinItemsFirstTimeThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(loadMoreTrashBinItemsThunk.pending, (state) => {
        state.loadingMoreStatus = LOADING_STATUSES.LOADING
      })
      .addCase(loadMoreTrashBinItemsThunk.fulfilled, (state, action) => {
        state.loadingMoreStatus = LOADING_STATUSES.SUCCEEDED
        adapter.addMany(state, action.payload)
      })
  },
})

const {
  reducer, actions
} = trashBinSlice

export { reducer as trashBinReducer, actions as trashBinActions }
