import _ from "lodash"

const getJwtToken = (item) => item._embedded?.covers?.[0]?._embedded?.previews?.jwt
const getUrlTemplate = (item) => item._embedded?.covers?.[0]?._embedded?.previews?.url_template
const getCoverItemLink = (item) => item._embedded?.cover_item?._links?.image_middle?.href

export const getPreviewAlbumLink = (item, size_name?) => {
  const jwt = getJwtToken(item)

  if (jwt) {
    const urlTemplate = _.template(getUrlTemplate(item), { interpolate: /\{(.+?)\}/g })

    return urlTemplate({ size_name }) + `&jwt=${jwt}`
  }

  return getCoverItemLink(item)
}
