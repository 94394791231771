import { IGetEmbeddedItemsSchema } from "@cloudike/web_photos/dist/types/intarfaces/IGetEmbeddedItemsSchema"
import { TimelinePaginator } from "@cloudike/web_photos/dist/types/lib/Paginator/TimelinePaginator"

import { getPhotoSdk } from "./photo"
import { SDK_TYPES } from "./sdkConstants"

export const getPhotoTimelineSdk = () => {
  const sdk = getPhotoSdk()
  const timeline = sdk.timeline

  return timeline
}

export const getPhotoFavoriteSdk = () => {
  const sdk = getPhotoSdk()

  return sdk.favorite
}

export const getFamilyPhotoFavoriteSdk = () => {
  const sdk = getPhotoSdk()

  return sdk.familyFavorite
}

export const getFamilyTimelineSdk = () => {
  const sdk = getPhotoSdk()
  const timeline = sdk.familyTimeline

  return timeline
}

export const getTimelineSdkByType = (type: SDK_TYPES) => {
  if (type === SDK_TYPES.FAMILY) {
    return getFamilyTimelineSdk()
  }

  return getPhotoTimelineSdk()
}

export const getFavoriteSdkByType = (type: SDK_TYPES) => {
  if (type === SDK_TYPES.FAMILY) {
    return getFamilyPhotoFavoriteSdk()
  }

  return getPhotoFavoriteSdk()
}

let currentPaginator: TimelinePaginator | null = null
let currentFamilyTimelinePaginator: TimelinePaginator | null = null

export const initTimelinePaginator = (type: SDK_TYPES, pageSize = 20, params: IGetEmbeddedItemsSchema) => {
  if (type === SDK_TYPES.FAMILY) {
    currentFamilyTimelinePaginator = getPhotoSdk().familyTimeline.getTimelineItemsPaginator(pageSize, params)

    return currentFamilyTimelinePaginator
  }

  currentPaginator = getPhotoSdk().timeline.getTimelineItemsPaginator(pageSize, params)

  return currentPaginator
}

export const getCurrentTimelinePaginator = (type: SDK_TYPES) => {
  if (type === SDK_TYPES.FAMILY) {
    return currentFamilyTimelinePaginator
  }

  return currentPaginator
}
