import { t } from 'i18next'
import * as yup from 'yup'

export const validationSchemaRenameMember = (maxLength = 40) => {
  return yup.object()
    .shape({
      name: yup
        .string()
        .required(t('l_common_fillField'))
        .max(maxLength, t('l_common_maxLength', { length: maxLength }))
    })
}
