import APP_CONFIG from "constants/configs/app.config"

import { USER_DATA_KEY_HEADER_CONTENT_TYPE } from "features/user/constants"
import { API_KEY_NAME, CONFIG_TYPE_PARAM_NAME } from "@cloudike/api-switcher"


export const redirectToExternalSSO = async function(pathname = '', withoutRedirectAfterAuthorization = false, to = '') {
  const headers = new Headers()
  headers.append(USER_DATA_KEY_HEADER_CONTENT_TYPE, "application/json")

  const url = APP_CONFIG.api + '/api/2/sso/tickets/'
  const data = { client_id: APP_CONFIG.client_id }
  const lang = localStorage.getItem('langKey')
  const configType = window.localStorage.getItem(API_KEY_NAME)
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  }

  try {
    const response = await fetch(url, requestOptions)
    const rsp =  await response.json()

    const urlSSO = new URL(rsp.url)

    if (!withoutRedirectAfterAuthorization && !to) {
      urlSSO.searchParams.set('to', window.location.href)
    }

    if (!!to) {
      urlSSO.searchParams.set('to', to)
    }

    if (lang) {
      urlSSO.searchParams.set('lang', lang)
    }

    if (pathname) {
      urlSSO.pathname = pathname
    }

    if(configType) {
      urlSSO.searchParams.set(CONFIG_TYPE_PARAM_NAME, configType)
    }

    window.location.href = urlSSO.href
  } catch(e) {
    console.log(e)
    throw new Error('redirectToExternalSSO has failed')
  }
}
