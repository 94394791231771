import React, { useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { SpriteIcon } from "@cloudike/web_ui_components"


export interface AlbumCardProps {
  id: string,
  onClick?: (id: string) => void,
  imgUrl?: string,
  description?: string,
  isViewed: boolean
}

export const MemoryCard: React.FC<AlbumCardProps> = ({
  imgUrl,
  description,
  onClick,
  id,
  isViewed,
}) => {
  const [imageLoaded, toggleImageLoading] = useState(false)
  const handleImgLoad = () => {
    toggleImageLoading(true)
  }

  const handleClick = () => {
    onClick!(id)
  }

  return (
    <MemoryBox>
      <MemoryCardBox
        onClick={handleClick}
        boxShadowColor={isViewed ? 'var(--gray-1)' : 'var(--accent-normal)'}
      >
        {
          imgUrl ? (
            <MemoryImg
              src={imgUrl}
              onLoad={handleImgLoad}
              className={classNames({ loading: !imageLoaded })}
            />
          ) : (
            <PlaceholderImgBox>
              <NoPhotoIcon iconName={'no_photo_album'} />
            </PlaceholderImgBox>
          )
        }

        <TextDescription>
          {description}
        </TextDescription>

      </MemoryCardBox>

    </MemoryBox>
  )
}

const PlaceholderImgBox = styled.div`
  z-index: 2;
  width: 100%;
  transition: all 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NoPhotoIcon = styled(SpriteIcon)`
  width: 32px;
  height: 32px;
  color: var(--icon-secondary)
`

const MemoryBox = styled.div`
  height: 150px;
  margin-right: 12px;

  :last-child {
    margin-right: 0;
  }
`

const TextDescription = styled.span`
  display: flex;
  position: absolute;
  max-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 10;
  left: 0;
  bottom: 0;
  color: var(--tag-text);
  width: 109px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  border-radius: 0 0 4px 4px;
`

const MemoryCardBox = styled.div`
  height: 144px;
  width: 113px;
  position: relative;
  cursor: pointer;
  background: var(--background-tertiary);
  user-select: none;
  border-radius: 4px;
  border: solid 2px var(--background-primary);
  box-shadow: 2px 2px ${props => props.boxShadowColor}, -2px -2px ${props => props.boxShadowColor}, 2px -2px ${props => props.boxShadowColor}, -2px 2px ${props => props.boxShadowColor};
`

const MemoryImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 200ms ease;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  border-radius: 4px;

  &.loading {
    opacity: 0;
  }
`
