import { getCurrentAppConfig } from "../features/common/configs/additionalConfigService"

import { Options, request } from "./request"

export const userApi = {
  updateUserData: (userId, data) => {
    return request('PATCH', `/api/2/users/${userId}`, data)
  },
  patchUserData: (userId, data) => {
    return request('PATCH', `/api/2/users/${userId}`, data)
  },
  getLinkedCloudsSources: (userId) => {
    return request('GET', `/api/2/users/${userId}/linked_accounts`)
  },
  getAllLinkedAccounts: (userId) => {
    return request('GET', `/api/3/users/${userId}/linked_accounts/all`, {}, { apiVersion: 3 })
  },
  getAuthUrl: (userId, source) => {
    return request('GET', `/api/3/users/${userId}/linked_accounts/${source}`)
  },
  getImportedTasks: (userId) => {
    return request('GET', `/api/2/users/${userId}/fs/import_tasks`)
  },
  getCredentials: (userId) => {
    return request('GET', `/api/2/users/${userId}/credentials`)
  },
  importTasks: (userId, accountId) => {
    return request('POST', `/api/2/users/${userId}/fs/import_tasks`, { account_id: accountId })
  },
  cancelImportTask: (userId, taskId) => {
    return request('PATCH', `/api/2/users/${userId}/fs/import_tasks/${taskId}/`, { cancelled: true })
  },
  checkImportTask: (userId, taskId) => {
    return request('GET', `/api/2/users/${userId}/fs/import_tasks/${taskId}/`, {}, { apiVersion: 3 })
  },
  removeLinkedCloud: (userId, accountId, type) => {
    return request('DELETE', `/api/2/users/${userId}/linked_accounts/${type}/${accountId}`, {})
  },
  removeAccount: (userId) => {
    return request('DELETE', `/api/2/users/${userId}`, {})
  },
  getSubscriptions: (userId) => {
    return request('GET', `users/${userId}/orders`, {}, { host: getCurrentAppConfig().provisioning_api })
  },
  getPromocodes: (userId) => {
    return request('GET', `users/${userId}/promocodes`, {}, { host: getCurrentAppConfig().provisioning_api })
  },
  applyPromocode: (userId, code) => {
    return request('POST', `users/${userId}/promocodes`, { code }, { host: getCurrentAppConfig().provisioning_api })
  },
  getTokens: (userId) => {
    return request('GET', `/api/2/users/${userId}/tokens`)
  },
  removeToken: (userId, tokenId) => {
    return request('DELETE', `/api/2/users/${userId}/tokens/${tokenId}/`)
  },
  getBasicAuth: () => {
    return request('GET', '/api/2/accounts/get_basic_auth')
  },
  removeLogin: (login) => {
    return request('POST', '/api/2/accounts/remove_login', { login })
  },
  logout: (token, userId) => {
    return request('DELETE', `/api/2/users/${userId}/tokens/${token}`)
  },
  createBasicAuth: (data) => {
    return request('POST', '/api/2/accounts/create_basic_auth', data)
  },
  getTelegramLink: (userId) => {
    return request('POST', `/api/2/users/${userId}/bots/telegram/`, {})
  },
  checkTelegramBot: (userId) => {
    return request('GET', `/api/2/users/${userId}/bots/telegram/`, {})
  },
  getStatistics: () => {
    return request('GET', '/api/2/accounts/get', { storage_stat: true })
  },
  getEvents: (options) => {
    return request('GET', '/api/2/events', options)
  },
  getReservePhone: (userId) => {
    return request('GET', `/api/3/users/${userId}/clp_user_info/reserve_phone`, {}, { apiVersion: 3 })
  },
  setReservePhone: (userId, data, opts: Options = {}) => {
    return request('PUT', `/api/3/users/${userId}/clp_user_info/reserve_phone`, data, { apiVersion: 3, ...opts })
  },
  createLinkedAccountRecord: (userId, source, data) => {
    return request('POST', `/api/3/users/${userId}/linked_accounts/${source}`, data, { apiVersion: 3 })
  },
  getUserSettings: (userId) => {
    return request('GET', `/api/2/users/${userId}/settings_values`)
  },
  updateUserSetting: (userId, settingKey, data) => {
    return request('PATCH', `/api/3/users/${userId}/settings/${settingKey}/value`, data)
  },
  getContentTypes: (userId) => {
    return request('GET', `/api/2/users/${userId}/photos/supported_formats/`)
  },
  sendActivity: (userId) => {
    return request('POST', `/api/3/users/${userId}/session_check_points/`)
  },
  getHeadersUploadPhoto: (userId, host) => {
    return request('HEAD', `/storage-api/0/users/${userId}/photos/uploads`, {}, { host: host })
  },
  getHeadersUploadFile: (userId, host) => {
    return request('HEAD', `/storage-api/0/users/${userId}/fs/root/uploads`, {}, { host: host })
  },
  getPhotoUrls: (userId) => {
    return request('GET', `/api/2/users/${userId}/photos`)
  },
  getFileUrls: (userId) => {
    return request('GET', `/api/2/users/${userId}/fs/root`)
  },
  removeAllTokens: (userId) => {
    return request('POST', `/api/2/users/${userId}/tokens_deleter/`, { with_current: true })
  },
  sendInviteToFamily: (userId, familyId, phoneOrEmail) => {
    return request('POST', `/api/2/users/${userId}/families/`, { with_current: true })
  }
}

