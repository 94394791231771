import APP_CONFIG from "../constants/configs/app.config"

import { changeUserNameForNumber } from "./changeUserNameForNumber"

const isPhoneNumber = (str: string, regexp = /^\+7\d{10}$/) => {
  return regexp.test(str)
}

export const getFormattedUsername = (userData, phoneMask) => {
  const { name } = userData
  return APP_CONFIG.formatUsername && isPhoneNumber(name) ? changeUserNameForNumber(name, phoneMask) : name
}
