import React from "react"

import { useTranslation } from "react-i18next"
import { ModalHeader, SpriteIcon } from "@cloudike/web_ui_components"
import styled from "@emotion/styled"

import { DialogModal } from "../../ui/DialogModal"

export const CreatePasswordWarningModal = ({ opened, onClose, onOk, }) => {
  const { t } = useTranslation()

  return (
    <DialogModal
      withLongButtons={true}
      isOpened={opened}
      title={t('l_common_alert')}
      onClose={onClose}
      okText={t('a_common_ok')}
      onOk={onOk}
      cancelText={t('a_common_cancel')}
      onCancel={onClose}
      header={(
        <SHeaderBox>
          <SModalHeader
            title={t('l_dw_passwordAlertTitle')}
            titleIcon={<SSpriteIcon iconName={'notification-warning'} />}
          />

          <SHeaderCloseButton iconName="close"
            onClick={onClose}
          />
        </SHeaderBox>
      )}
    >
      <STextInModalBox>
        {t('l_dw_passwordAlertMessage')}
      </STextInModalBox>
    </DialogModal>
  )
}

const SModalHeader = styled(ModalHeader)`
  h2 {
    align-items: center;
  }
`

const SSpriteIcon = styled(SpriteIcon)`
  && {
    width: 32px;
    height: 32px;
  }
`

const SHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const SHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-primary);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`

const STextInModalBox = styled.span`
  font-size: 14px;
  max-width: 580px;
  color: var(--text-secondary);
`