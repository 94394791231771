import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { ProfileImportFromOtherClouds } from "features/user/features/other-clouds/ProfileImportFromOtherClouds"
import { ProfileLanguage } from "features/user/features/profile/ProfileLanguage"
import { ProfileMainInfo } from "features/user/features/profile/ProfileMainInfo"
import { ProfileGooglePolicyText } from "features/user/features/profile/ProfileGooglePolicyText"
import { ProfilePageContentBox } from "features/user/features/profile/ProfileStyledComponents"
import { ProfileTabs, ProfileTabsTypes } from "features/user/features/profile/ProfileTabs"
import { useTranslation } from "react-i18next"
import styled from 'styled-components'

import { getUserSettingsSelector } from "../../../features/user/selectors"
import { useElectronDetection } from "../../../features/common/hooks/useElectronDetection"

const ProfileAccount = () => {
  const { t } = useTranslation()

  usePageTitle('l_settings_profileAndSettingsTitle')

  const userSettings = getUserSettingsSelector()

  const url = new URL(window.location.href)
  const isChangePassword = url.searchParams.get('isChangePassword')
  const isElectronAvailable = useElectronDetection()


  return (
    <>
      <PageTopbar title={t('l_settings_profileAndSettingsTitle')} />

      <ProfilePageContentBox>
        <ProfileTabs activeTab={ProfileTabsTypes.ACCOUNT} />

        <Title>
          {t('l_settings_profileTitle')}
        </Title>

        <ProfileMainInfo isChangePassword={!!isChangePassword} />

        <ProfileLanguage />

        {!isElectronAvailable && (
          <>
            <ProfileImportFromOtherClouds />

            {userSettings?.['user_cloud_import']?.enable_google_drive && <ProfileGooglePolicyText />}
          </>
        )}

      </ProfilePageContentBox>
    </>
  )
}

const Title = styled.h2`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

export default ProfileAccount
