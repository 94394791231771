import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { useCallback, useEffect, useRef } from 'react'

import styled from 'styled-components'
import { PublicLinkPasswordModal } from 'features/public-link/PublicLinkPasswordModal'
import { fetchPublicLinkInfoThunk, loadJustUploadedSharedAlbumItemsThunk, publicLinkActions, PublicLinksErrorTypes, unSubscribePublicLinkToWsThunk } from 'features/public-link/publicLinkSlice'
import { useAppDispatch } from  "store"
import { useNavigate, useParams } from 'react-router-dom'
import { PublicLinkPlaceholder, PublicLinkPlaceholderBtnTypes } from 'features/public-link/PublicLinkPlaceholder'
import { getIsUserAuthorizedSelector } from 'features/user/userSlice'
import {
  getPublicLinkErrorSelector,
  getPublicLinkAlbumDataSelector,
  getPublicLinkLoadingStatusSelector,
  getPublicLinkSelectedItemsCountSelector,
  getPublicLinkTotalItemsCountSelector,
  getIsSizeQuotaExceedErrorSelector
} from 'features/public-link/selectors'
import { useTranslation } from 'react-i18next'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { PageTopbar } from 'features/common/page-topbar'
import { useMobileDetection } from 'features/common/hooks'
import { PublicAlbum } from 'features/public-link/PublicAlbum'
import { getAlbumDateStr } from 'features/albums/utils/albumDate'
import { PhotosGrid } from '@cloudike/web_ui_components'
import { useUploadingFinishDetection } from 'features/common/files-uploading/useUploadingFinishDetection'
import { usePageTitle } from 'features/common/hooks/usePageTitle'

import { hideNotification, NOTIFICATION_TYPES, showNotification } from "../../../features/common/notifications"
import { goToSubscriptionsPage } from "../../../utils/subscriptions"

export const PublicAlbumPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams()
  const isMobile = useMobileDetection()

  const isAuthorized = getIsUserAuthorizedSelector()
  const error = getPublicLinkErrorSelector()
  const selectedItemsCount = getPublicLinkSelectedItemsCountSelector()
  const status = getPublicLinkLoadingStatusSelector()
  const totalItemsCount = getPublicLinkTotalItemsCountSelector()
  const albumData = getPublicLinkAlbumDataSelector()

  usePageTitle('l_common_pagePhotoAlbum')

  useEffect(() => {
    dispatch(fetchPublicLinkInfoThunk({ id, navigate }))

    return () => {
      dispatch(unSubscribePublicLinkToWsThunk())
    }
  }, [])

  useUploadingFinishDetection(() => dispatch(loadJustUploadedSharedAlbumItemsThunk()))

  const handleCancelSelection = useCallback(() => {
    dispatch(publicLinkActions.unselectAll())
  }, [])

  const sizeQuotaExceedNotification = useRef(null)
  const isSizeQuotaExceedError = getIsSizeQuotaExceedErrorSelector()

  useEffect(() => {
    if (isSizeQuotaExceedError && !sizeQuotaExceedNotification.current) {
      const notificationOptions = {
        isPermanent: true,
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_spaceError'),
        message: t('l_notification_copyError'),
        typeError: 'SizeQuotaExceeded',
        callback: () => {
          hideNotification(sizeQuotaExceedNotification.current)
          sizeQuotaExceedNotification.current = null
          goToSubscriptionsPage()
        }
      }

      sizeQuotaExceedNotification.current = showNotification(notificationOptions)
    }
  }, [isSizeQuotaExceedError])

  const getPlaceholderProps = (error) => {
    switch (error) {
    case PublicLinksErrorTypes.NOT_EXIST:
      return {
        texts: {
          title: t('l_common_linkNotExist'),
          description: t('l_common_linkExpiredMessage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()
      }
    case PublicLinksErrorTypes.EMPTY_ALBUM:
      return {
        texts: {
          title: t('l_albums_noPhotosTitle'),
          description: '',
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn')
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO()
      }
    case PublicLinksErrorTypes.SOMETHING_WENT_WRONG:
      return {
        texts: {
          title: t('l_common_linkExpired'),
          description: t('l_common_linkExpiredMessage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
      }
    case PublicLinksErrorTypes.NO_ACCESS:
      return {
        texts: {
          title: t('l_common_noAccessTitle'),
          description: !isAuthorized ? t('l_common_logInToSee') : t('l_common_noAccessMesage'),
          btn: isAuthorized ? t('a_common_backToMyFiles') : t('a_common_logIn'),
          createAccount: !isAuthorized && t('a_common_signUp'),
        },
        btnType: isAuthorized ? PublicLinkPlaceholderBtnTypes.SECONDARY : PublicLinkPlaceholderBtnTypes.PRIMARY,
        onButtonClick: isAuthorized ? () => window.location.href = '/' : () => redirectToExternalSSO(),
        onCreateAccount: isAuthorized ? null : () => redirectToExternalSSO('/signup'),
      }
    }
  }

  return (
    <>
      {status !== LOADING_STATUSES.LOADING && !error && (
        <PageTopbar
          title={albumData.description}
          selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
          selectedItemsCount={selectedItemsCount}
          onCancelSelection={handleCancelSelection}
          description={!!albumData && `${getAlbumDateStr(albumData)} ${t('l_common_countElements', { number: totalItemsCount })}`}
        />
      )}

      {!!error && (
        <PublicLinkPlaceholder {...getPlaceholderProps(error)} />
      )}

      {
        status !== LOADING_STATUSES.LOADING && !error && (
          <PublicAlbum />
        )
      }

      {
        status === LOADING_STATUSES.LOADING && !error && (
          <Skeleton />
        )
      }

      <PublicLinkPasswordModal />
    </>
  )
}

const Skeleton = () => {
  return (
    <>
      <PageTopbarTopSkeleton />

      <PageTopbarBottomSkeleton />

      <PhotosGrid>
        {Array.from({ length: 20 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

const PageTopbarTopSkeleton = styled.div`
  margin-top: 30px;
  background: var(--background-tertiary);
  height: 25px;
  width: 250px;
`

const PageTopbarBottomSkeleton = styled.div`
  margin-top: 8px;
  margin-bottom: 25px;
  background: var(--background-tertiary);
  height: 25px;
  width: 150px;
`
