import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { AlbumType } from '@cloudike/web_photos'
import { IAlbumSchema } from '@cloudike/web_photos/dist/types/intarfaces/IAlbumSchema'
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAlbumsSdkByType } from 'sdk/albums'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { RootState } from 'store'

import { TOTAL_COUNT_HEADER } from "../../../constants/headers"

export type IExtendedAlbumPlacesSchema =  IAlbumSchema

const adapter = createEntityAdapter<IExtendedAlbumPlacesSchema>()

export const albumsPlacesSelectors = adapter.getSelectors()

const getCurrentAlbumsType = (state: RootState) => state.albumsPlaces.type
const getPlaceType = (state: RootState) => state.albumsPlaces.typePlace

export const fetchPlacesAlbumsThunk = createAsyncThunk(
  'albumsPlaces/fetchAlbumsPlacesThunk',
  async function(_, { getState }) {
    const state = getState() as RootState
    const type = getCurrentAlbumsType(state)
    const albumsSdk = getAlbumsSdkByType(type)
    const typePlace = getPlaceType(state)
    const response = await albumsSdk.getAlbums({ offset: 0, type: [typePlace], total_count: true, preview_jwt: true })
    return {
      items: response.data._embedded.albums,
      totalCount: parseInt(response.headers[TOTAL_COUNT_HEADER]) || 0
    }
  }
)

export const albumsPlacesSlice = createSlice({
  name: 'albumsPlaces',
  initialState: adapter.getInitialState({
    status: LOADING_STATUSES.LOADING,
    error: '',
    type: SDK_TYPES.DEFAULT,
    typePlace: AlbumType.PLACE,
    totalCount: 0
  }),
  reducers: {
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setCurrentAlbumsType: (state, action) => {
      state.type = action.payload
    },
    setPlacesType: (state, action) => {
      state.typePlace = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    resetState: (state) => {
      state.status = LOADING_STATUSES.LOADING
      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlacesAlbumsThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchPlacesAlbumsThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload.items)
        state.totalCount = action.payload.totalCount
      })
      .addCase(fetchPlacesAlbumsThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

const {
  reducer, actions
} = albumsPlacesSlice

export { reducer as albumsPlacesReducer, actions as albumsPlacesActions }
