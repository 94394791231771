import { getModalsRootElement } from 'constants/modals'
import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useEffect, useState } from 'react'

import {
  CopyMoveModal as CopyMoveModalFromUiKit,
  FilesTableBreadcrumbs,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon,
  STYLED_VARIABLES,
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"
import { IFsNodeSchema } from '@cloudike/web_files'
import { validationSchemaForFileItem } from 'features/files/validationSchemaForFileItem'

import { useResizeWindow } from "../../common/hooks/useResizeWindow"
import { THEME } from "../../user/userSlice"
import { getUiTheme } from "../../user/selectors"
import { getFilesPublicLinkShareIdSelector } from "../selectors"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"
import { appActions } from "../../../store/app"

import {
  copyItemsThunk,
  createCopyMoveFolderThunk,
  fetchCopyMoveNodesThunk,
  moveItemsThunk,
  publicLinkCopyMoveModalActions
} from './publicLinkCopyMoveModalSlice'
import {
  getPublicLinkCopyMoveModalBreadcrumbsSelector,
  getPublicLinkCopyMoveModalNodesSelector,
  getPublicLinkCopyMoveModalStatusSelector
} from './selectors'

export enum CopyMoveModalType {
    COPY = 'COPY',
    MOVE = 'MOVE'
}

enum FooterButtonsTypes {
    SECONDARY = 'SECONDARY',
    PRIMARY = 'PRIMARY',
}

interface Props {
    type: CopyMoveModalType,
    selectedItems: IFsNodeSchema[],
    currentFileFolder: string,
    onClose: () => void
}

export const PublicLinkCopyMoveModal: React.FC<Props> = ({
  selectedItems,
  type,
  currentFileFolder,
  onClose
}) => {
  const dispatch = useAppDispatch()

  const nodes = getPublicLinkCopyMoveModalNodesSelector()
  const breadcrumbs = getPublicLinkCopyMoveModalBreadcrumbsSelector()
  const status = getPublicLinkCopyMoveModalStatusSelector()
  const sharedId = getFilesPublicLinkShareIdSelector()

  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.id || sharedId
  const currentFolderForActions = breadcrumbs[breadcrumbs.length - 1]?.id || ''
  const [isNewFolderVisible, setIsNewFolderVisible] = useState(false)
  const widthWindow = useResizeWindow()

  const { t } = useTranslation()

  const theme = getUiTheme()

  useEffect(() => {
    dispatch(fetchCopyMoveNodesThunk({ parentId: currentFolderForActions }))
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [breadcrumbs])

  const handleCreateFolder = (values) => {
    const callback = () => {
      setIsNewFolderVisible(false)
    }

    dispatch(createCopyMoveFolderThunk({ parentId: currentFolderForActions, name: values.name, callback }))
  }

  const handleCancelFolderCreation = () => {
    setIsNewFolderVisible(false)
  }

  const handleClose = () => {
    onClose()
    dispatch(publicLinkCopyMoveModalActions.resetState())
  }

  const handleOpenFolder = (folder) => {
    const { name, id } = folder

    dispatch(publicLinkCopyMoveModalActions.addBreadcrumb({ name, id }))
  }

  const handleBredcrumbClick = (breadcrumb) => {
    dispatch(publicLinkCopyMoveModalActions.goToBreadcrumb(breadcrumb))
  }


  const handleCreateNewFolderClick = async () => {
    setIsNewFolderVisible(true)
  }

  const handleActivate = () => {
    if (type === CopyMoveModalType.COPY) {
      dispatch(copyItemsThunk({ items: selectedItems, parentId: currentFolder === sharedId ? '' : currentFolder }))
    } else {
      if (currentFolder === currentFileFolder) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_moveFolderError')
        })

        onClose()
        return
      }

      dispatch(moveItemsThunk({ items: selectedItems, parentId: currentFolder === sharedId ? '' : currentFolder }))
    }

    handleClose()
  }

  const buttons = [
    {
      type: FooterButtonsTypes.SECONDARY,
      className: 'modal-dialog__cancel',
      actionName: t('a_common_close'),
      onAction: handleClose,
    },
    {
      type: FooterButtonsTypes.PRIMARY,
      className: 'modal-dialog__button_action',
      actionName: type === CopyMoveModalType.COPY ? t('a_common_copy') : t('a_common_move'),
      onAction: handleActivate,
    },
  ]

  const buttonList = buttons.map((props) => {
    switch (props.type) {
    case FooterButtonsTypes.PRIMARY:
      return (
        <PrimaryButton
          key={props.actionName}
          actionName={props.actionName}
          onAction={props.onAction}
          // disabled={currentFolder === currentFileFolder}
        />
      )
    case FooterButtonsTypes.SECONDARY:
      return (
        <SecondaryButtonBox
          key={props.actionName}
          actionName={props.actionName}
          onAction={props.onAction}
        />
      )
    }
  })

  const title = (type === CopyMoveModalType.COPY ?
    t('l_common_copyItemsFormatTitle', { number: selectedItems.length }) :
    t('l_common_moveItemsTitle', { number: selectedItems.length })) + ' ' + breadcrumbs[breadcrumbs.length - 1]?.name

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return (
    <ModalDialog
      setIsModalVisible={handleVisibleModal}
      isOpened={true}
      parentBlock={getModalsRootElement()}
      header={<ModalHeader title={title} />}
      footer={(
        <ModalFooter
          createNewFolderButton={(
            <CreateFolderButtonBox>
              <CreateFolderButton
                disabled={isNewFolderVisible}
                onClick={handleCreateNewFolderClick}
              >
                <StyledSpriteIcon
                  iconName="create_folder"
                />

                {t('a_files_createFolder')}
              </CreateFolderButton>
            </CreateFolderButtonBox>
          )}
          buttonList={buttonList}
        />
      )}
      onCloseModal={handleClose}
    >
      <CopyMoveModalFromUiKit
        theme={theme}
        isLoading={status === LOADING_STATUSES.LOADING}
        isNewItemVisible={isNewFolderVisible}
        validateSchema={validationSchemaForFileItem}
        breadcrumbs={
          breadcrumbs.length > 0 ? (
            <FilesTableBreadcrumbs
              widthWindow={widthWindow}
              firstItem={t('l_common_allFiles')}
              filePath={breadcrumbs}
              onClickedPath={handleBredcrumbClick}
            />
          ) : null
        }
        newFolderDefaultName={t('l_files_createFolderPlaceholder')}
        filesList={nodes as any}
        activeElements={selectedItems}
        onCreateFolder={handleCreateFolder}
        onCancelFolderCreation={handleCancelFolderCreation}
        onGoToFolder={handleOpenFolder}
        emptyFolderContent={!isNewFolderVisible && status !== LOADING_STATUSES.LOADING && (
          <EmptyFolderBox>
            {theme === THEME.LIGHT ?
              <EmptyFolderImg src={formatImgUrl("/assets/placeholders-images/no-albums.svg")} /> :
              <EmptyFolderImg src={formatImgUrl("/assets/placeholders-images-black/no-albums.svg")} />}

            <EmptyFolderText>
              {t('l_common_itemPlaceholder')}
            </EmptyFolderText>
          </EmptyFolderBox>
        )}
      />

      <MobileCreateFolderButtonBox>
        <CreateFolderButton
          disabled={isNewFolderVisible}
          onClick={handleCreateNewFolderClick}
        >
          <StyledSpriteIcon
            iconName="create_folder"
          />

          {t('a_files_createFolder')}
        </CreateFolderButton>
      </MobileCreateFolderButtonBox>
    </ModalDialog>
  )
}

const CreateFolderButtonBox = styled.div`
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_60}) {
    visibility: hidden;
  }
`

const MobileCreateFolderButtonBox = styled.div`
  display: none;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_60}) {
    display: block;
    margin-top: 14px;
  }
`

const SecondaryButtonBox = styled(SecondaryButton)`
  margin-right: 8px;
`

const CreateFolderButton = styled.button`
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 500;

  &:disabled {
    color: #8e8e93;
  }
`

const StyledSpriteIcon = styled(SpriteIcon)`
  margin-right: 8px;
  color: var(--accent-normal);

  ${CreateFolderButton}:disabled & {
    color: #8e8e93;
  }
`

const EmptyFolderBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const EmptyFolderImg = styled.img`
  width: 186px;
  height: auto;
  pointer-events: none;
`

const EmptyFolderText = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  max-width: 220px;
  color: #5f738c;
  text-align: center;
`
