import { ACCEPTED_FILES_TYPES_FOR_DOCUMENTS } from 'constants/acceptedFileTypes'

import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from  "store"
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { openPhotoPreviewThunk, PREVIEW_TYPES } from 'features/photo/photo-preview/photoPreviewSlice'
import { PageSpiner, PhotoCard, PhotosGrid } from '@cloudike/web_ui_components'
import { usePreviewItemsUpdate } from 'features/photo/photo-preview/usePreviewItemsUpdate'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import classNames from "classnames"
import { LoadingOverlay } from 'ui/LoadingOverlay'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { EmptyDocumentAlbumPlaceholder } from './EmptyDocumentAlbumPlaceholder'
import {
  documentsActions,
  fetchMoreDocumentItems,
  resetDocumentsAccessTimerThunk,
  uploadDocumentWalletFilesThunk
} from './documentsSlice'
import { getDocumentItemsActionStatusSelector, getDocumentItemsSelector, getDocumentSelectedItemsIdsSelector, getDocumentsWalletTypesSelector } from './selectors'
import { DOCUMENT_ITEMS_ACTION_STATUSES } from './types'
import { getTextLoadingOverlay } from "./DocumentsTypes"

const TRANSPARENT_IMAGE_URL = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

interface DocumentAlbumProps {
  className?: string,
  id: string
}

export const DocumentAlbum: React.FC<DocumentAlbumProps> = ({ className = '', id }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const items = getDocumentItemsSelector() as any
  const selectedItemsIds = getDocumentSelectedItemsIdsSelector()
  const selectedItemsCount = selectedItemsIds.length
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()
  const status = getDocumentItemsActionStatusSelector()
  const types = getDocumentsWalletTypesSelector()

  const type = types.find(type => type.id === id)
  const totalItemsCount = type.documents_count

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(fetchMoreDocumentItems({ offset: items.length, typeId: type.id }))
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items.length])

  usePreviewItemsUpdate(items, totalItemsCount)

  const handleItemSelection = useCallback((id) => {
    dispatch(documentsActions.selectItem(id))
    dispatch(resetDocumentsAccessTimerThunk())
  }, [])

  const handleUploadPhotos = (files) => {
    dispatch(uploadDocumentWalletFilesThunk({ files, id }))
  }

  const handleOpenPreview = useCallback((id) => {
    analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_PREVIEW_VIEW)

    dispatch(openPhotoPreviewThunk({
      items,
      currentItemId: id,
      sdkType: SDK_TYPES.DEFAULT,
      type: PREVIEW_TYPES.DOCUMENTS,
      totalItemsCount,
      loadMore
    }))

    dispatch(resetDocumentsAccessTimerThunk())
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      handleUploadPhotos(acceptedFiles)
    }
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: false
    }))
    dispatch(dropzoneActions.setLocalAvailableExtensions(ACCEPTED_FILES_TYPES_FOR_DOCUMENTS))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
      dispatch(dropzoneActions.setLocalAvailableExtensions(null))
    }
  }, [])

  if (!items.length) {
    return (
      <DocumentAlbumBox
        className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
      >
        <EmptyDocumentAlbumPlaceholder
          texts={{
            title: t('l_dw_emptyTitle'),
            description: t('l_dw_emptyMessage'),
            uploadBtn: t('a_common_uploadPhotos')
          }}
          onUploadFiles={handleUploadPhotos}
        />

        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />


        <LoadingOverlay text={getTextLoadingOverlay(status, t)}
          visible={status !== DOCUMENT_ITEMS_ACTION_STATUSES.IDLE}
        />
      </DocumentAlbumBox>
    )
  }

  return (
    <DocumentAlbumBox
      className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
    >
      <DocumentAlbumItemsBox>
        <PhotosGrid>
          {items.map(item => (
            <PhotoCard
              key={item.id}
              id={item.id}
              imgUrl={item?.previewUrl || TRANSPARENT_IMAGE_URL}
              isSelected={selectedItemsIds.includes(item.id)}
              onSelect={handleItemSelection}
              onClick={handleOpenPreview}
              isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
              type={item.type}
            />
          ))}
        </PhotosGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </DocumentAlbumItemsBox>

      <DropzoneArea
        visible={isDropzoneAreaVisible}
      />

      <LoadingOverlay text={getTextLoadingOverlay(status, t)}
        visible={status !== DOCUMENT_ITEMS_ACTION_STATUSES.IDLE}
      />
    </DocumentAlbumBox>
  )
}

const DocumentAlbumBox = styled.div`
  width: 100%;
  position: relative;
  height: 100%;

  &.with-overflow {
    max-height: calc(100vh - 120px);
    overflow: hidden;
  }
`

const DocumentAlbumItemsBox = styled.div`
  width: 100%;
  height: calc(100% - 107px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`
