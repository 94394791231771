import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useEffect } from "react"

import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "store"
import { AlbumSkeleton, PhotosGrid } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { PublicLinksTabs } from "features/public-links-albums/PublicLinksTabs"
import {
  loadPublicLinksItemsFirstTimeThunk,
  PUBLIC_LINKS_TYPES,
  publicLinksActions,
  subscribePublicLinksToWSThunk,
  unsubscribePublicLinksFromWSThunk
} from "features/public-links-albums/publicLinksSlice"
import {
  getPublicLinksLoadingStatusSelector,
  getPublicLinksSelectedItemsSelector
} from "features/public-links-albums/selectors"
import { PublicAlbums } from "features/public-links-albums/PublicLinksAlbums"
import styled from "@emotion/styled";

interface PublicLinksPageProps {
  type: PUBLIC_LINKS_TYPES
}

const PublicLinksAlbumsPage: React.FC<PublicLinksPageProps> = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const status = getPublicLinksLoadingStatusSelector()
  const selectedItems = getPublicLinksSelectedItemsSelector()

  usePageTitle('a_nav_publicLinks')

  useEffect(() => {
    dispatch(loadPublicLinksItemsFirstTimeThunk({ type }))
    dispatch(subscribePublicLinksToWSThunk())

    return () => {
      dispatch(publicLinksActions.resetState())
      dispatch(unsubscribePublicLinksFromWSThunk())
    }
  }, [])

  return (
    <>
      <SPageTopbar
        title={t('a_nav_publicLinks')}
      />

      <PublicLinksTabs activeTab={type} />

      {
        status === LOADING_STATUSES.LOADING &&
        <Skeleton />
      }

      {
        status !== LOADING_STATUSES.LOADING &&
        <PublicAlbums type={type} />
      }

      {!!selectedItems.length && <MobileToolbarMenuToggle />}
    </>
  )
}

const SPageTopbar = styled(PageTopbar)`
  padding-bottom: 20px;
`

const Skeleton = () => {
  return (
    <PhotosGrid>

      {Array.from({ length: 14 }).map((_, index) => {
        return (
          <AlbumSkeleton key={index} />
        )
      })}

    </PhotosGrid>
  )
}

export default PublicLinksAlbumsPage
