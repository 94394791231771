import { useEffect, useState } from "react"

import { getCookieByName } from "utils/cookie"
import { logoutThunk, subscribeUserToWSThunk, updateUserDataThunk } from "features/user/userSlice"
import { useAppDispatch } from "store"
import { redirectToExternalSSO } from "utils/externalSSO"
import { useLocation } from "react-router-dom"
import { ROUTES_AVAILABLE_WITHOUT_AUTHORIZATION } from "routing/routes"

import { USER_DATA_KEY_IN_STORAGE } from "../constants"
import { WS_EVENTS_NAMES } from "../../../constants/wsEventsNames"
import { getPhotosWS } from "../../../sdk/photo"
import { addContentTypesThunk, sendActivityThunk } from "../../../store/app"
import { request } from "../../../api/request"
import { familyActions } from "../../family/familySlice"

export enum AUTH_STATUSES {
  UNAUTHORIZED = 'UNAUTHORIZED',
  AUTHORIZED = 'AUTHORIZED',
  UNKNOWN = 'UNKNOWN'
}

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const accountsQuerySkip = location.pathname.startsWith('/offers')  // hack for android app to prevent infinite reloading /offers page

  const [authStatus, toggleAuthorizationStatus] = useState(AUTH_STATUSES.UNKNOWN)

  const redirectToAuthPage = () => {
    const isRedirectNecessary = !ROUTES_AVAILABLE_WITHOUT_AUTHORIZATION.some((route) => {
      if (route.strict) {
        return route.pathname === location.pathname
      }

      return location.pathname.startsWith(route.pathname)
    })

    if (isRedirectNecessary) {
      redirectToExternalSSO()
    } else {
      toggleAuthorizationStatus(AUTH_STATUSES.UNAUTHORIZED)
    }
  }

  useEffect(() => {
    const tokenFromCookies = getCookieByName('sso_token')

    const clientDataFromLocalStorage = localStorage.getItem(USER_DATA_KEY_IN_STORAGE)
    const tokenFromStorage = !!JSON.parse(clientDataFromLocalStorage)?.CurrentUser?.token

    const token = new URLSearchParams(window.location.search).get('token')

    if (token) {
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify({ CurrentUser: { token } }))
      localStorage.setItem('CLIENT', JSON.stringify({ token }))

      return
    }

    if (clientDataFromLocalStorage && !!tokenFromStorage) {
      return
    }

    if (tokenFromCookies) {
      localStorage.setItem(USER_DATA_KEY_IN_STORAGE, JSON.stringify({ CurrentUser: { token: tokenFromCookies } }))
      localStorage.setItem('CLIENT', JSON.stringify({ token: tokenFromCookies }))

      return
    }

    redirectToAuthPage()
  }, [])


  useEffect(() => {
    const fetchAccountData = async () => {
      if (accountsQuerySkip) {
        return
      }

      try {
        const userLinks = await request('GET', '/api/2/users')
        const userLink = userLinks?._links?.current_user?.href

        if (!userLink) {
          localStorage.clear()
          redirectToAuthPage()

          return
        }

        const data = await request('GET', userLink, {}, { host: null })

        const familiesResponse = await request('GET', data._links.families.href, { limit: 1 }, { host: null })
        const familyData = familiesResponse._embedded.families?.[0]
        const familyId = familyData?.shared_user_id
        const familyOwnerId = familyData?.owner_id

        // userid and user_id are old fields for backward compatibility
        dispatch(updateUserDataThunk({ ...data, userid: data.id, user_id: data.id, family_user_id: familyId, is_owner_family: familyId ? data.id === familyOwnerId : null }))
        dispatch(familyActions.setFamilyData(familyData))
        dispatch(subscribeUserToWSThunk())
        dispatch(addContentTypesThunk())
        dispatch(sendActivityThunk())
        toggleAuthorizationStatus(AUTH_STATUSES.AUTHORIZED)
      } catch (error) {
        if ((error as any)?.status === 401) {
          localStorage.clear()
          redirectToAuthPage()
        }
      }
    }

    fetchAccountData()
  }, [])

  useEffect(() => {
    if (authStatus !== AUTH_STATUSES.AUTHORIZED) {
      return
    }

    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.UNAUTHORIZED, () => {
      dispatch(logoutThunk())
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.AUTH_TOKEN_DELETED, () => {
      dispatch(logoutThunk())
    })
  }, [authStatus])

  return authStatus
}
