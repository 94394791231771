export const events = {
  WEB_TIMELINE_CLICK:{
    firebase: {
      eventName: 'web_timeline_click'
    },
  },
  WEB_PHOTO_VIDEO_CLICK: {
    firebase: {
      eventName: 'web_photo_video_click'
    }
  },
  WEB_PHOTO_REMOVE_CLICK: {
    firebase: {
      eventName: 'web_photo_remove_click'
    }
  },
  WEB_PHOTO_DOWNLOAD_CLICK: {
    firebase: {
      eventName: 'web_photo_download_click'
    }
  },
  WEB_PHOTO_COPY_TO_FC_CLICK: {
    firebase: {
      eventName: 'web_photo_copy_to_fc_click'
    }
  },
  WEB_PHOTO_NEW_ALBUM_CLICK: {
    firebase: {
      eventName: 'web_photo_new_album_click'
    }
  },
  WEB_PHOTO_UPLOAD_CLICK: {
    firebase: {
      eventName: 'web_photo_upload_click'
    }
  },
  WEB_MY_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_my_albums_click'
    }
  },
  WEB_ALBUM_REMOVE_CLICK: {
    firebase: {
      eventName: 'web_album_remove_click'
    }
  },
  WEB_ALBUM_ADD_TO_ALBUM_CLICK: {
    firebase: {
      eventName: 'web_album_add_to_album_click'
    }
  },
  WEB_FC_TIMELINE_CLICK: {
    firebase: {
      eventName: 'web_fc_timeline_click'
    }
  },
  WEB_FC_PHOTO_UPLOAD_CLICK: {
    firebase: {
      eventName: 'web_fc_photo_upload_click'
    }
  },
  WEB_FC_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_fc_albums_click'
    }
  },
  WEB_FC_MANAGE_CLICK: {
    firebase: {
      eventName: 'web_fc_manage_click'
    }
  },
  WEB_FC_MANAGE_DELETE_FC_CLICK: {
    firebase: {
      eventName: 'web_fc_manage_delete_fc_click'
    }
  },
  WEB_FOLDER_CLICK: {
    firebase: {
      eventName: 'web_folder_click'
    }
  },
  WEB_FILE_CLICK: {
    firebase: {
      eventName: 'web_file_click'
    }
  },
  WEB_FILE_SELECT: {
    firebase: {
      eventName: 'web_file_select'
    }
  },
  WEB_FOLDER_SELECT: {
    firebase: {
      eventName: 'web_folder_select'
    }
  },
  WEB_UPLOAD_FILES_CLICK: {
    firebase: {
      eventName: 'web_upload_files_click'
    }
  },
  WEB_UPLOAD_FOLDER_CLICK: {
    firebase: {
      eventName: 'web_upload_folder_click'
    }
  },
  WEB_CREATE_FOLDER_CLICK: {
    firebase: {
      eventName: 'web_create_folder_click'
    }
  },
  WEB_FILE_MOVE_CLICK: {
    firebase: {
      eventName: 'web_file_move_click'
    }
  },
  WEB_FILE_COPY_CLICK: {
    firebase: {
      eventName: 'web_file_copy_click'
    }
  },
  WEB_PUBLIC_LINKS_CLICK: {
    firebase: {
      eventName: 'web_public_links_click'
    }
  },
  WEB_PUBLIC_FILE_FOLDER_SELECT: {
    firebase: {
      eventName: 'web_public_file_folder_select'
    }
  },
  WEB_PUBLIC_LINK_SETTINGS_CLICK: {
    firebase: {
      eventName: 'web_public_link_settings_click'
    }
  },
  WEB_PUBLIC_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_public_albums_click'
    }
  },
  WEB_PUBLIC_FC_ALBUMS_CLICK: {
    firebase: {
      eventName: 'web_public_fc_albums_click'
    }
  },
  WEB_TRASH_BIN_CLICK: {
    firebase: {
      eventName: 'web_trash_bin_click'
    }
  },
  WEB_EMPTY_TRASHBIN_CLICK: {
    firebase: {
      eventName: 'web_empty_trashbin_click'
    }
  },
  WEB_TRASHBIN_FULLRESTORE_CLICK: {
    firebase: {
      eventName: 'web_trashbin_fullrestore_click'
    }
  },
  WEB_TRASHBIN_FOLDER_SELECT: {
    firebase: {
      eventName: 'web_trashbin_folder_select'
    }
  },
  WEB_TRASHBIN_FILE_SELECT: {
    firebase: {
      eventName: 'web_trashbin_file_select'
    }
  },
  WEB_TRASHBIN_DELETE_CLICK: {
    firebase: {
      eventName: 'web_trashbin_delete_click'
    }
  },
  WEB_TRASHBIN_PHOTOS_CLICK: {
    firebase: {
      eventName: 'web_trashbin_photos_click'
    }
  },
  WEB_TRASHBIN_FC_PHOTOS_CLICK: {
    firebase: {
      eventName: 'web_trashbin_fc_photos_click'
    }
  },
  WEB_GOOGLE_PLAY_CLICK: {
    firebase: {
      eventName: 'web_google_play_click'
    }
  },
  WEB_APP_STORE_CLICK: {
    firebase: {
      eventName: 'web_app_store_click'
    }
  },
  WEB_FEEDBACK_CLICK: {
    firebase: {
      eventName: 'web_feedback_click'
    }
  },
  WEB_PROFILE_SETTINGS_CLICK: {
    firebase: {
      eventName: 'web_profile_settings_click'
    }
  },
  WEB_LOGOUT_CLICK: {
    firebase: {
      eventName: 'web_logout_click'
    }
  }
}
