export const changeUserNameForNumber = (userName: string, phoneMask: string) => {
  const number = userName.replace('+', '').replaceAll(' ','')
  const phoneMaskNumber = phoneMask.replace('+', '')
  const pattern = phoneMaskNumber.split(' ')

  const maskPhoneArray = []
  pattern.forEach((item, index) => {
    maskPhoneArray[index] = item.length
  })

  const regExpArrayLastIndex = maskPhoneArray.length - 1
  const regExpArrayFirstIndex = 0

  let regExp = ''
  let replaceValue = ''

  maskPhoneArray.forEach((item, index) => {
    replaceValue = replaceValue + `$${index + 1} `
    switch (index) {
    case regExpArrayFirstIndex:
      regExp = regExp + `^(\\d{${item}})`
      break
    case regExpArrayLastIndex:
      regExp = regExp + `(\\d{${item}})$`
      break
    default:
      regExp = regExp + `(\\d{${item}})`
    }
  })
  const regexTestIsNumber = /[^\p{L}\s]/u

  const isNumber = regexTestIsNumber.test(userName)
  const patternRegExp = new RegExp(regExp)

  return isNumber ? '+' + number.replace(patternRegExp, replaceValue) : userName
}
