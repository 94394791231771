import { useEffect } from 'react'

type Handler = (event: MouseEvent | TouchEvent) => void

export function useOnClickOutside(ref: React.RefObject<HTMLElement>, handler: Handler, additionalRefs: React.RefObject<HTMLElement>[] = []) {
  useEffect(() => {

    const listener: Handler = (event) => {
      const target = event.target as HTMLElement

      if (
        !ref.current ||
        ref.current.contains(target) ||
        additionalRefs.some((ref) => ref && ref.current && ref.current.contains(target))
      ) {
        return
      }

      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, additionalRefs])
}
