import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userApi } from 'api/userApi'
import { RootState } from 'store'

export enum RESERVE_PHONE_MODAL_STEPS {
  code = 'code',
  phone = 'phone',
  tooManyRequests = 'too-many-requests'
}

const initialState = {
  opened: false,
  phone: '',
  tempPhone: '',
  currentStep: RESERVE_PHONE_MODAL_STEPS.phone
}

const insertSpacesToString = (str, spaceAfter) => {
  return [...str].reduce((r, char, index) => r += `${char}${spaceAfter.includes(index) ? ' ' : ''}`, '')
}

export const fetchReservePhoneThunk = createAsyncThunk(
  'reservePhone/fetchReservePhoneThunk',
  async function(_, { dispatch, getState }) {
    const state = getState() as RootState

    try {
      const response = await userApi.getReservePhone(state.user.userData.id)

      dispatch(actions.setPhone(insertSpacesToString(response.phone, [1, 4, 7, 9])))
    } catch (error) {
      if (error.code === 'NotFound') {
        dispatch(actions.setPhone(''))
      }
    }
  }
)

export const setReservePhoneThunk = createAsyncThunk(
  'reservePhone/setReservePhoneThunk',
  async function(
    { phone, errorCallback, successCallback, signal }:
    { phone: string, errorCallback: (error: any) => void, successCallback: () => void, signal: AbortSignal },
    { getState }) {
    const state = getState() as RootState

    try {
      await userApi.setReservePhone(state.user.userData.id, { phone, sms_otp: '' }, { signal })

      successCallback()
    } catch (error) {
      errorCallback(error)
    }
  }
)

export const sendReservePhoneCodeThunk = createAsyncThunk(
  'reservePhone/sendReservePhoneCodeThunk',
  async function(
    { phone, code, errorCallback, successCallback, signal }:
    { phone: string, code: string, errorCallback: (error: any) => void, successCallback: () => void, signal: AbortSignal },
    { getState }) {
    const state = getState() as RootState

    try {
      await userApi.setReservePhone(state.user.userData.id, { phone, sms_otp: code }, { signal })

      successCallback()
    } catch (error) {
      errorCallback(error)
    }
  }
)

export const reservePhoneSlice = createSlice({
  name: 'reservePhone',
  initialState,
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload
    },
    setTempPhone: (state, action) => {
      state.tempPhone = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    toggleModal: (state, action) => {
      state.opened = action.payload
    },
    closeModal: (state) => {
      state.opened = false,
      state.tempPhone = '',
      state.currentStep = RESERVE_PHONE_MODAL_STEPS.phone
    }
  },
})

const { reducer, actions } = reservePhoneSlice

export { reducer as reservePhoneReducer, actions as reservePhoneActions }
