import React, { useEffect, useMemo, useRef } from "react"

import { Form, Formik } from 'formik'
import {
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SpriteIcon,
  STYLED_VARIABLES
} from '@cloudike/web_ui_components'
import styled from "styled-components"
import passportImg from 'assets/icons/passport.svg'
import * as yup from "yup"
import { t } from "i18next"
import { FormikProps } from "formik/dist/types"

import { appActions } from "../../store/app"
import { useAppDispatch } from "../../store"

type OptionsForPopupCreateNewDocument = {
    texts: any;
    onSubmitModal: (newName: string) => void;
    onCloseModal: () => void;
    isShown: boolean;
    parentBlock: any;
    renamingName: string;
};

export const CreateDocumentModal = ({
  onCloseModal,
  onSubmitModal,
  texts,
  isShown,
  parentBlock,
  renamingName,
}: OptionsForPopupCreateNewDocument) => {

  if (!isShown) {
    return null
  }

  const initialValues = {
    name: renamingName
  }

  const dispatch = useAppDispatch()

  const formikRef = useRef<FormikProps<any>>()
  const inputRef = useRef<HTMLInputElement>()

  const handleSubmit = (values: any) => {
    onSubmitModal(values.name)
  }

  useEffect(() => {
    if (isShown) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [isShown])

  const handleKeyUp = handleSubmit => (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const validationSchema = useMemo(() => yup.object().shape({
    name: yup
      .string()
      .required(t('l_common_fillField'))
      .matches(/[^\s]/, t('l_common_fillField'))
  }), [])

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, handleSubmit, setErrors }) => (
        <Form className="modal-dialog__form">
          <ModalDialog
            setIsModalVisible={handleVisibleModal}
            header={(
              <>
                <ModalHeader
                  title={texts.TITLE}
                  titleIcon={(
                    <IconWarning src={passportImg}
                      className="modal-dialog__title_icon"
                    />
                  )}
                />

                <CloseIcon
                  iconName={'close'}
                  onClick={onCloseModal}
                />
              </>
            )}
            footer={(
              <ModalFooter
                classNameButtonsBox={'modal-dialog__buttons-space-between'}
                withoutShadowLine
                buttonList={[
                  <CloseButtonBox
                    key={'buttonCancel'}
                    actionName={texts.NAME_BUTTON_CANCEL}
                    onAction={() => {
                      setErrors({ name: '' })
                      onCloseModal()
                    }}
                  />,
                  <ButtonActionBox
                    key={'buttonAction'}
                    actionName={texts.NAME_BUTTON_ACTION}
                    onAction={handleSubmit}
                  />
                ]}
              />
            )}
            parentBlock={parentBlock}
            isOpened={isShown}
            onCloseModal={() => {
              setErrors({ name: '' })
              onCloseModal()
            }}
          >
            <TitleBox>
              {texts.SUB_TITLE}
            </TitleBox>

            <InputGroupBox>
              <InputBox
                name="name"
                className={`${errors.name && 'invalid-input'}`}
                type="text"
                placeholder={texts.PLACEHOLDER}
                maxLength={40}
                autoFocus
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleChange}
                value={values.name}
                onKeyUp={handleKeyUp(handleSubmit)}
                ref={inputRef}
              />

              {errors.name && (
                <WarningIcon iconName="warning" />
              )}
            </InputGroupBox>

            <ErrorMessageBox>
              {errors.name as string}
            </ErrorMessageBox>
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

const InputBox = styled.input`
  background: var(--background-primary);
  color: var(--text-primary);
  border: 1px solid var(--stroke-primary);
  box-shadow: inset 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 4px;
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  padding: 10px 35px 10px 15px;
  margin-bottom: 4px;

  :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }

  &:focus {
    border-color: var(--accent-normal);
  }

  &.invalid-input {
    border: 1px solid #F62434;
  }
`

const InputGroupBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  width: 100%;
  position: relative;
`

const TitleBox = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 152.34%;
  color: var(--text-primary);
`

const ErrorMessageBox = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #f62434;
  min-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 451px;
  overflow: hidden;
`

const ButtonActionBox = styled(PrimaryButton)`
  height: 40px;
  background: var(--button-primary-default);
  border-radius: 4px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--button-primary-text);
  cursor: pointer;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 100%;
  }
`

const CloseButtonBox = styled(SecondaryButton)`
  height: 40px;
  border: 1px solid var(--stroke-primary);
  border-radius: 4px;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  margin-right: 8px;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    width: 100%;
  }
`

const IconWarning = styled.img`
  width: 32px;
  height: 32px;
`

const CloseIcon = styled(SpriteIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

const WarningIcon = styled(SpriteIcon)`
  margin-left: 9px;
  width: 24px;
  height: 24px;
  color: #f62434;
  user-select: none;
  position: absolute;
  right: 10px;
`
