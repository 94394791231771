import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getFilesSdk } from 'sdk/files'
import { OrderType } from '@cloudike/web_ui_components'
import { IFsNodeSchema } from '@cloudike/web_files'

export enum SortColumns {
 NAME = 'name',
 SIZE = 'file_info.size',
}

const adapter = createEntityAdapter<IFsNodeSchema>()

export const folderSelectionModalSelectors = adapter.getSelectors()

const initialState = {
  status: LOADING_STATUSES.IDLE,
  error: null,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  currentFolderId: '',
  breadcrumbs: [{ name: '', id: '' }]
}

export const fetchFolderSelectionNodesThunk = createAsyncThunk(
  'FolderSelectionModal/fetchFolderSelectionNodesThunk',
  async ({ parentId = '' }: { parentId?: string }) => {
    const sdk = getFilesSdk()

    const response = await sdk.folderOperationsService.getAllFolderContent({ parent_id: parentId, preview: true, preview_jwt: true }, 500)

    const nodes = response.filter(node => !node.is_trashed)

    return nodes
  }
)

export const FolderSelectionModalSlice = createSlice({
  name: 'FolderSelectionModal',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    setCurrentFolderId: (state, action) => {
      state.currentFolderId = action.payload
    },
    addBreadcrumb: (state, action) => {
      state.breadcrumbs.push(action.payload)
    },
    goToBreadcrumb: (state, action) => {
      const indexOfBreadcrumb = state.breadcrumbs.findIndex(breadcrumb => breadcrumb.id === action.payload.id)

      state.breadcrumbs = state.breadcrumbs.slice(0, indexOfBreadcrumb + 1)
    },
    addFolder: (state, action) => {
      adapter.setAll(state, [action.payload, ...folderSelectionModalSelectors.selectAll(state)])
    },
    resetState: (state) => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })

      adapter.removeAll(state)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFolderSelectionNodesThunk.pending, (state) => {
        state.status = LOADING_STATUSES.LOADING
      })
      .addCase(fetchFolderSelectionNodesThunk.fulfilled, (state, action) => {
        state.status = LOADING_STATUSES.SUCCEEDED
        adapter.setAll(state, action.payload)
      })
      .addCase(fetchFolderSelectionNodesThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
  },
})

export default FolderSelectionModalSlice.reducer

const {
  reducer, actions
} = FolderSelectionModalSlice

export { reducer as folderSelectionModalReducer, actions as folderSelectionModalActions }
