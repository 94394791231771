import { LOADING_STATUSES } from "constants/loadingStatuses"

import { useCallback, useEffect, useMemo } from "react"

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate, useParams } from "react-router-dom"
import { AlbumPageTopbar, PhotosGrid } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"
import { usePageTitle } from "features/common/hooks/usePageTitle"
import {
  getDocumentItemsLoadingStatusSelector,
  getDocumentSelectedItemsIdsSelector,
  getDocumentsWalletTypesSelector,
  getDocumentTypeTotalItemsCountSelector,
  getIsDocumentNameEditingSelector
} from "features/documents/selectors"
import { useDocumentsWalletAccessChecking } from "features/documents/useDocumentsWalletAccessChecking"
import { DocumentAlbum } from "features/documents/DocumentAlbum"
import { documentsActions, fetchDocumentItems, renameDocumentTypeThunk, resetDocumentsAccessTimerThunk } from "features/documents/documentsSlice"
import { useMobileDetection } from "features/common/hooks"
import * as yup from "yup"

import { photoPreviewActions } from "../../../features/photo/photo-preview/photoPreviewSlice"
import { getDocumentTypePersonThunk, getPersonsThunk, personsActions } from "../../../features/documents/persons/personsSlice"
import { getPersonsSelector } from "../../../features/documents/persons/selectors"
import { getOwnerData } from "../Persons"

const DocumentPage: React.FC = () => {
  const { id, person_id } = useParams<{ id?: string, person_id?: string }>()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const types = getDocumentsWalletTypesSelector()
  const status = getDocumentItemsLoadingStatusSelector()
  const selectedItemsIds = getDocumentSelectedItemsIdsSelector()
  const persons = getPersonsSelector()
  const isDocumentNameEditing = getIsDocumentNameEditingSelector()
  const totalItemsCount = getDocumentTypeTotalItemsCountSelector(id)

  const selectedItemsCount = selectedItemsIds.length

  const currentPersonId = !person_id ? 'owner' : person_id

  const type = types.find(t => t.id === id)

  const isMobile = useMobileDetection()

  usePageTitle(t('a_nav_documentWallet'))

  useDocumentsWalletAccessChecking()

  useEffect(() => {
    dispatch(getDocumentTypePersonThunk({ currentPersonId }))

    return () => {
      dispatch(documentsActions.setShowCreateModal(false))
    }
  }, [persons])

  useEffect(() => {
    if (!type || (!!person_id && !persons.some(item => item.id === person_id))) {
      return navigate('/documents')
    }

    dispatch(resetDocumentsAccessTimerThunk())

    if (persons.length === 0 ) {
      getPersons()
    }

    dispatch(documentsActions.setCurrentDocumentTypeId(type.id))
    dispatch(fetchDocumentItems({ typeId: type.id }))

    return () => {
      dispatch(photoPreviewActions.resetState())
      dispatch(documentsActions.resetDocumentItemsState())
    }
  }, [type?.id])

  const getPersons = async () => {
    await dispatch(getPersonsThunk({ ...getOwnerData(t) }))
    await dispatch(personsActions.changeSelectPerson(currentPersonId))
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleCancelSelection = useCallback(() => {
    dispatch(documentsActions.unselectAll())
  }, [])

  const handleStartEditing = () => {
    type.preview_id === 'document' && dispatch(documentsActions.toggleDocumentNameEditStatus(true))
  }

  const handleFinishEditing = async (name: string) => {
    type.name !== name && await dispatch(renameDocumentTypeThunk({ id, name }))
    dispatch(documentsActions.toggleDocumentNameEditStatus(false))
  }

  const getTexts = () => ({
    dates: '',
    photosCount: t('l_common_countElements', { number: totalItemsCount })
  })

  const validationSchema = useMemo(() => yup.object().shape({
    name: yup
      .string()
      .required(t('l_common_fillField'))
      .matches(/[^\s]/, t('l_common_fillField'))
  }), [])

  if (!types.length) {
    return null
  }

  return (
    !!type && (
      <>
        <AlbumPageTopbar
          title={type.name}
          selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedItemsCount }) : t('l_files_selectedFiles', { number: selectedItemsCount })}
          selectedItemsCount={selectedItemsCount}
          placeholder={isMobile ? t('l_albums_enterAlbumNameShort') : t('l_albums_enterAlbumName')}
          isEditing={type.preview_id === 'document' && isDocumentNameEditing}
          onCancelSelection={handleCancelSelection}
          onStartEdit={handleStartEditing}
          onFinishEdit={handleFinishEditing}
          onBack={handleBack}
          texts={getTexts()}
          validationSchema={validationSchema}
        />

        {
          (status !== LOADING_STATUSES.LOADING || type.documents_count === 0) && (
            <DocumentAlbum
              id={id}
            />
          )
        }

        {
          status === LOADING_STATUSES.LOADING && type.documents_count !== 0 &&
        <Skeleton />
        }

        <MobileToolbarMenuToggle iconName={'more_horiz'} />
      </>
    )
  )
}

const Skeleton = () => {
  return (
    <>
      <PhotosGrid>
        {Array.from({ length: 12 }).map((_, index) => {
          return (
            <SkeletonCard key={index} />
          )
        })}
      </PhotosGrid>
    </>
  )
}

const SkeletonCard = styled.div`
  background: var(--background-tertiary);
  width: 100%;
  padding-bottom: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
`

export default DocumentPage
