import React, { useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { useAppDispatch } from "store"
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { openPhotoPreviewThunk, PREVIEW_TYPES } from 'features/photo/photo-preview/photoPreviewSlice'
import { PageSpiner, PhotoCard, PhotosGrid } from '@cloudike/web_ui_components'
import { usePreviewItemsUpdate } from 'features/photo/photo-preview/usePreviewItemsUpdate'
import { uploadAlbumItemsThunk } from 'features/common/files-uploading/filesUploadingSlice'
import { DropzoneArea } from 'features/common/dropzone/DropzoneArea'
import { getIsDropzoneAreaVisibleSelector } from 'features/common/dropzone/selectors'
import { dropzoneActions, removeUploadHandlerThunk, setUploadHandlerThunk } from 'features/common/dropzone/dropzoneSlice'
import classNames from "classnames"

import { REDIRECT_TO } from "../../../constants/searchParams"

import {
  getAlbumItemsSelector,
  getAlbumSelectedItemsCountSelector,
  getAlbumSelectedItemsIdsSelector,
  getAlbumTotalItemsCountSelector
} from './selectors'
import { albumActions, loadMoreAlbumItemsThunk } from './albumSlice'
import { EmptyAlbumPlaceholder } from './EmptyAlbumPlaceholder'

interface AlbumProps {
  className?: string,
  id: string,
  type: SDK_TYPES,
  isPlace?: boolean,
  isSeason?: boolean,
  isDropZoneDisabled?: boolean,
  isCalendar?: boolean
}

export const Album: React.FC<AlbumProps> = (
  {
    className = '',
    id,
    type,
    isPlace = false,
    isSeason = false,
    isDropZoneDisabled = true,
    isCalendar = false
  }
) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const items = getAlbumItemsSelector()
  const totalItemsCount = getAlbumTotalItemsCountSelector()
  const selectedItemsCount = getAlbumSelectedItemsCountSelector()
  const selectedItemsIds = getAlbumSelectedItemsIdsSelector()
  const isDropzoneAreaVisible = getIsDropzoneAreaVisibleSelector()

  const { ref: loaderBoxRef, inView: isLoaderInView } = useInView()

  const loadMore = () => {
    dispatch(loadMoreAlbumItemsThunk())
  }

  useEffect(() => {
    if (items.length && isLoaderInView) {
      loadMore()
    }
  }, [isLoaderInView, items])

  usePreviewItemsUpdate(items, totalItemsCount)

  const handleItemSelection = useCallback((id) => {
    dispatch(albumActions.selectItem(id))
  }, [])

  const handleAddPhotos = () => {
    if (type === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums/${id}/add`)
    }

    if (type === SDK_TYPES.FAMILY) {
      const redirectToPath = location.pathname
      const redirectPathParam = `${REDIRECT_TO}=${redirectToPath}`

      navigate(`/photos/albums/${id}/add?${redirectPathParam}`)
    }
  }

  const getTypeAlbum = () => (
    isCalendar ? PREVIEW_TYPES.CALENDAR :
      isPlace ? PREVIEW_TYPES.PLACE_ALBUM :
        PREVIEW_TYPES.ALBUM
  )

  const handleOpenPreview = useCallback((id) => {
    dispatch(openPhotoPreviewThunk({
      items,
      currentItemId: id,
      sdkType: type,
      type: getTypeAlbum(),
      totalItemsCount,
      loadMore
    }))
  }, [items, totalItemsCount])

  const handleUploadFiles = (acceptedFiles: FileList) => {
    if (acceptedFiles.length) {
      dispatch(uploadAlbumItemsThunk({ files: acceptedFiles, type, id }))
    }
  }

  useEffect(() => {
    dispatch(setUploadHandlerThunk({ callback: handleUploadFiles }))
    dispatch(dropzoneActions.updateDropzoneOptions({
      disabled: isDropZoneDisabled || isPlace || isSeason
    }))

    return () => {
      dispatch(removeUploadHandlerThunk())
      dispatch(dropzoneActions.updateDropzoneOptions({
        disabled: true
      }))
    }
  }, [])

  if (!items.length) {
    return (
      <AlbumBox
        className={className}
      >
        <EmptyAlbumPlaceholder
          texts={{
            title: t('l_albums_noPhotosTitle'),
            description: t('l_albums_noPhotosMessage'),
            uploadBtn: t('a_common_addPhotosToAlbum')
          }}
          onButtonClick={isSeason || isPlace ? null : handleAddPhotos}
        />

        {!isDropZoneDisabled && !isPlace && !isSeason && (
          <DropzoneArea
            visible={isDropzoneAreaVisible}
          />
        )}
      </AlbumBox>
    )
  }

  return (
    <AlbumBox
      className={classNames(className, { 'with-overflow': isDropzoneAreaVisible })}
    >
      <AlbumItemsBox>
        <PhotosGrid>
          {items.map(item => (
            <PhotoCard
              key={item.id}
              id={item.id}
              imgUrl={(item._links as any)?.image_middle?.href}
              isSelected={selectedItemsIds.includes(item.id)}
              onSelect={handleItemSelection}
              onClick={handleOpenPreview}
              isCheckboxVisibleWithoutHover={selectedItemsCount > 0}
              type={item.type}
              isFavorite={item.favorite}
            />
          ))}
        </PhotosGrid>

        {items.length < totalItemsCount && (
          <div ref={loaderBoxRef}>
            <StyledPageSpinner  />
          </div>
        )}
      </AlbumItemsBox>

      {!isDropZoneDisabled && !isPlace && !isSeason && (
        <DropzoneArea
          visible={isDropzoneAreaVisible}
        />
      )}
    </AlbumBox>
  )
}

const AlbumBox = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 107px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &.with-overflow {
    overflow: hidden;
  }
`

const AlbumItemsBox = styled.div`
  width: 100%;
`

const StyledPageSpinner = styled(PageSpiner)`
  margin: 20px 0;
  height: 40px;
  width: 100%;
`
