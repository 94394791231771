import React from "react"

import FilesPage from "pages/Files"
import TimelinePage from 'pages/Timeline'
import AlbumsPage from 'pages/Albums'
import CreateAlbumPage from 'pages/Albums/Create'
import FamilyTimelinePage from 'pages/Family/Timeline'
import ManageFamilyPage from 'pages/Family/ManageFamily'
import { TimelineToolbar } from "features/photo/timeline/TimelineToolbar"
import { AlbumsToolbar } from "features/albums/albums-list/AlbumsToolbar"
import AlbumPage from "pages/Albums/Album"
import { AlbumToolbar } from "features/albums/album/AlbumToolbar"
import AddingPhotosToAlbumPage from "pages/Albums/Album/AddingPhotosToAlbum"
import { SDK_TYPES } from "sdk/sdkConstants"
import { ManageFamilyToolbar } from "features/family/ManageFamilyToolbar"
import AddingPhotosToFamilyCloudPage from "pages/Family/AddingPhotosToFamilyCloud"
import ProfileAccount from "pages/Profile/Account"
import ProfilePurchases from "pages/Profile/Purchases"
import ProfileSecurity from "pages/Profile/Security"
import ProfileActivity from "pages/Profile/Activity"
import TrashBinPhotosPage from "pages/TrashBin/Photos"
import { TRASH_BIN_TYPES } from "features/trash-bin/trashBinSlice"
import { TrashBinToolbar } from "features/trash-bin/TrashBinToolbar"
import PublicLinksAlbumsPage from "pages/PublicLinks/Albums"
import { PUBLIC_LINKS_TYPES } from "features/public-links-albums/publicLinksSlice"
import { PublicLinksToolbar } from "features/public-links-albums/PublicLinksToolbar"
import { ConnectionsPage } from "pages/Connections"
import DocumentsPage from "pages/Documents"
import DocumentPage from "pages/Documents/Document"
import { DocumentsRoot } from "features/documents/DocumentsRoot"
import { DocumentTypesToolbar } from "features/documents/DocumentTypesToolbar"
import { DocumentAlbumToolbar } from "features/documents/DocumentAlbumToolbar"
import { FilesToolbar } from "features/files/FilesToolbar"
import TrashBinFilesPage from "pages/TrashBin/Files"
import PublicLinksFilesPage from "pages/PublicLinks/Files"
import { PublicLinksFilesToolbar } from "features/public-links-files/PublicLinksFilesToolbar"

import AddingPhotosToFavorite from "../pages/Favorites/AddFavorite/AddingPhotosToFavorite"
import AlbumsPlacesPage from "../pages/Albums/AlbumsPlaces"
import AlbumPlacePage from "../pages/Albums/AlbumsPlaces/AlbumPlace"
import AlbumsSeasonsPage from "../pages/Albums/AlbumsSeasons"
import { PersonsPage } from "../pages/Documents/Persons"
import SearchPlacesPage from "../pages/SearchPlaces"
import { AddingPhotosToAlbumToolbar } from "../features/albums/album/AddingPhotosToAlbumToolbar"
import { AddingFavoritesToolbar } from "../features/favorites/AddingFavoritesToolbar"
import { AlbumsPeoplePage } from "../pages/Albums/AlbumsPeople"
import { AlbumPeoplePage } from "../pages/Albums/AlbumsPeople/AlbumPeople"
import { withCreateFamilyPlaceholder } from "../features/family/CreateFamilyPlaceholder"

interface Route {
  path?: string,
  component: React.FC,
  rightSidebarComponent?: React.FC,
  withoutRightSidebar?: boolean,
  isIndex?: boolean
}

type Routes = (Route & { nestedRoutes?: Route[] })[]

export const routes: Routes = [
  {
    path: "/drive",
    component: FilesPage,
    rightSidebarComponent: null,
    nestedRoutes: [
      {
        component: FilesPage,
        rightSidebarComponent: FilesToolbar,
        isIndex: true
      },
      {
        path: "*",
        component: FilesPage,
        rightSidebarComponent: FilesToolbar
      }
    ]
  },

  {
    path: "/photos",
    component: TimelinePage,
    rightSidebarComponent: () => <TimelineToolbar type={SDK_TYPES.DEFAULT} />
  },

  {
    path: "/photos/albums",
    component: () => <AlbumsPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => <AlbumsToolbar type={SDK_TYPES.DEFAULT} />
  },

  {
    path: "/photos/albums/places",
    component: () => <AlbumsPlacesPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: null
  },

  {
    path: "/photos/albums/people",
    component: () => <AlbumsPeoplePage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: null
  },

  {
    path: "/search/places",
    component: () => <SearchPlacesPage />,
    rightSidebarComponent: null
  },

  {
    path: "/search/photos/albums/:id",
    component: () => (
      <AlbumPage
        type={SDK_TYPES.DEFAULT}
        disableActionsWithAlbum
        isDropZoneDisabled
        isCalendar={true}
      />
    ),
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.DEFAULT}
        hideWithoutSelection
        isCalendar={true}
      />
    )
  },

  {
    path: "/photos/albums/seasons",
    component: () => <AlbumsSeasonsPage />,
    rightSidebarComponent: () => (
      <AlbumsToolbar type={SDK_TYPES.DEFAULT}
        isSeason={true}
      />
    )
  },

  {
    path: "/photos/favorites/add",
    component: () => <AddingPhotosToFavorite type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => <AddingFavoritesToolbar />
  },

  // {
  //   path: "/family/photos/favorites",
  //   component: FavoritesPage,
  //   rightSidebarComponent: () => <FavoriteToolbar type={SDK_TYPES.FAMILY} />
  // },
  //
  {
    path: "/family/photos/favorites/add",
    component: () => <AddingPhotosToFavorite type={SDK_TYPES.FAMILY} />,
    rightSidebarComponent: () => <AddingFavoritesToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/family/photos/albums",
    component: withCreateFamilyPlaceholder(() => <AlbumsPage type={SDK_TYPES.FAMILY} />, 'l_albums_albumsTitle'),
    rightSidebarComponent: () => <AlbumsToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/family/photos/albums/places",
    component: withCreateFamilyPlaceholder(() => <AlbumsPlacesPage type={SDK_TYPES.FAMILY} />, 'l_albums_albumsTitle'),
    rightSidebarComponent: null
  },

  {
    path: "/family/photos/albums/people",
    component: withCreateFamilyPlaceholder(() => <AlbumsPeoplePage type={SDK_TYPES.FAMILY} />, 'l_albums_albumsTitle'),
    rightSidebarComponent: null
  },

  {
    path: "/family/photos/albums/people/:id",
    component: withCreateFamilyPlaceholder(() => <AlbumPeoplePage type={SDK_TYPES.FAMILY} />, 'l_albums_albumsTitle'),
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.FAMILY}
        isPerson={true}
      />
    )
  },

  {
    path: "/photos/albums/create",
    component: () => <CreateAlbumPage type={SDK_TYPES.DEFAULT} />,
  },

  {
    path: "/family/photos/albums/create",
    component: () => <CreateAlbumPage type={SDK_TYPES.FAMILY} />,
  },

  {
    path: "/photos/albums/:id",
    component: () => <AlbumPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => <AlbumToolbar type={SDK_TYPES.DEFAULT} />
  },

  {
    path: "/photos/albums/places/:id",
    component: () => <AlbumPlacePage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.DEFAULT}
        isPlace={true}
      />
    )
  },

  {
    path: "/photos/albums/people/:id",
    component: () => <AlbumPeoplePage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.DEFAULT}
        isPerson={true}
      />
    )
  },

  {
    path: "/photos/albums/seasons/:id",
    component: () => (
      <AlbumPage type={SDK_TYPES.DEFAULT}
        isSeason={true}
      />
    ),
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.DEFAULT}
        isSeason={true}
      />
    )
  },

  {
    path: "/family/photos/albums/places/:id",
    component: () => <AlbumPlacePage type={SDK_TYPES.FAMILY} />,
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.FAMILY}
        isPlace={true}
      />
    )
  },

  {
    path: "/photos/albums/:id/update",
    component: () => <AlbumPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent: () => <AlbumToolbar type={SDK_TYPES.DEFAULT} />
  },

  {
    path: "/family/photos/albums/:id",
    component: () => <AlbumPage type={SDK_TYPES.FAMILY} />,
    rightSidebarComponent: () => <AlbumToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/family/search/photos/albums/:id",
    component: () => (
      <AlbumPage type={SDK_TYPES.FAMILY}
        disableActionsWithAlbum
        isCalendar={true}
      />
    ),
    rightSidebarComponent: () => (
      <AlbumToolbar type={SDK_TYPES.FAMILY}
        hideWithoutSelection
        isCalendar={true}
      />
    )
  },

  {
    path: "/family/photos/albums/:id/update",
    component: () => <AlbumPage type={SDK_TYPES.FAMILY} />,
    rightSidebarComponent: () => <AlbumToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/photos/albums/:id/add",
    component: () => <AddingPhotosToAlbumPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent:() => <AddingPhotosToAlbumToolbar type={SDK_TYPES.DEFAULT} />,
  },

  {
    path: "/family/photos/albums/:id/add/personal",
    component: () => <AddingPhotosToAlbumPage type={SDK_TYPES.DEFAULT} />,
    rightSidebarComponent:() => <AddingPhotosToAlbumToolbar type={SDK_TYPES.DEFAULT} />,
  },

  {
    path: "/family/photos/albums/:id/add",
    component: () => <AddingPhotosToAlbumPage type={SDK_TYPES.FAMILY} />,
    rightSidebarComponent:() => <AddingPhotosToAlbumToolbar type={SDK_TYPES.FAMILY} />,
  },

  {
    path: "/family/photos",
    component: FamilyTimelinePage,
    rightSidebarComponent: () => <TimelineToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/family/photos/add",
    component: AddingPhotosToFamilyCloudPage,
    rightSidebarComponent: null,
    withoutRightSidebar: true
  },

  {
    path: "/profile/account",
    component: ProfileAccount
  },

  {
    path: "/profile/details",
    component: ProfilePurchases
  },

  {
    path: "/profile/settings",
    component: ProfileSecurity
  },

  {
    path: "/profile/log",
    component: ProfileActivity
  },

  {
    path: "/connections/:source",
    component: ConnectionsPage
  },

  {
    path: "/family/manage",
    component: ManageFamilyPage,
    rightSidebarComponent: () => <ManageFamilyToolbar />
  },

  {
    path: "/shared/files",
    component: () => <PublicLinksFilesPage />,
    rightSidebarComponent: () => <PublicLinksFilesToolbar />
  },

  {
    path: "/photos/shared-by-me",
    component: () => <PublicLinksAlbumsPage type={PUBLIC_LINKS_TYPES.ALBUMS} />,
    rightSidebarComponent: () => <PublicLinksToolbar type={SDK_TYPES.DEFAULT} />
  },

  {
    path: "/photos/family/shared-by-me",
    component: () => <PublicLinksAlbumsPage type={PUBLIC_LINKS_TYPES.FAMILY_ALBUMS} />,
    rightSidebarComponent: () => <PublicLinksToolbar type={SDK_TYPES.FAMILY} />
  },

  {
    path: "/global-trash/files",
    component: () => <TrashBinFilesPage />,
    rightSidebarComponent: () => <TrashBinToolbar type={TRASH_BIN_TYPES.FILES} />
  },

  {
    path: "/global-trash/photos",
    component: () => <TrashBinPhotosPage type={TRASH_BIN_TYPES.PHOTOS} />,
    rightSidebarComponent: () => <TrashBinToolbar type={TRASH_BIN_TYPES.PHOTOS} />
  },

  {
    path: "/global-trash/family-photos",
    component: () => <TrashBinPhotosPage type={TRASH_BIN_TYPES.FAMILY_PHOTOS} />,
    rightSidebarComponent: () => <TrashBinToolbar type={TRASH_BIN_TYPES.FAMILY_PHOTOS} />
  },
  {
    path: "/documents",
    component: DocumentsRoot,
    rightSidebarComponent: null,
    nestedRoutes: [
      {
        component: DocumentsPage,
        rightSidebarComponent: DocumentTypesToolbar,
        isIndex: true
      },
      {
        path: ":id",
        component: DocumentPage,
        rightSidebarComponent: DocumentAlbumToolbar
      },
      {
        path: "person/:person_id/:id",
        component: DocumentPage,
        rightSidebarComponent: DocumentAlbumToolbar
      },
    ]
  },
  {
    path: "/documents/persons",
    component: PersonsPage,
    rightSidebarComponent: null
  },
]

export const ROUTES_AVAILABLE_WITHOUT_AUTHORIZATION = [
  {
    pathname: '/invite',
    strict: true
  },
  {
    pathname: '/links/pa',
    strict: false
  },
  {
    pathname: '/links/fs',
    strict: false
  },
  {
    pathname: '/public',
    strict: false
  },
  {
    pathname: '/signin',
    strict: true
  },
  {
    pathname: '/offers',
    strict: false
  },
]
