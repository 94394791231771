import { useAppSelector } from "store"
import { createSelector } from "@reduxjs/toolkit"

import { albumsSelectors } from "./albumsSlice"

export const getAlbumsLoadingStatusSelector = () => useAppSelector(state =>
  state.albums.status
)

export const getLoadingAlbumSelector = () => useAppSelector(state =>
  state.album.isLoading
)

const getAlbumsSelectedItemsRawSelector = state => albumsSelectors.selectAll(state.albums)

export const getAlbumsSelectedItemsSelector = () => useAppSelector(
  createSelector(
    getAlbumsSelectedItemsRawSelector,
    albums =>
      albums.filter(item => item.selected)
  )
)

export const getAlbumsSelectedItemsCountSelector = () => useAppSelector(
  createSelector(
    getAlbumsSelectedItemsRawSelector,
    albums =>
      albums.filter(item => item.selected).length
  )
)

export const getAlbumsItemsSelector = () => useAppSelector(getAlbumsSelectedItemsRawSelector)

