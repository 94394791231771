import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { useAppDispatch } from  "store"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { getOffersContentThunk } from "../../../store/app"
import { getUiTheme } from "../../user/selectors"
import APP_CONFIG from "../../../constants/configs/app.config"
import { useMobileDetection } from "../../common/hooks"

const OfferView = () => {
  const dispatch = useAppDispatch()
  const { offerId } = useParams()
  const isMobile = useMobileDetection(767)
  const { t } = useTranslation()
  const theme = getUiTheme()
  const navigateToOffer = (url: string) => window.location.href = url
  const validTypesArray = ['privacy_policy', 'terms_and_conditions', 'promo_overall']
  const lang = navigator.language.slice(0,2)
  const langs = APP_CONFIG.i18n.offerLangs ?? APP_CONFIG.i18n.langs
  const defaultLang = APP_CONFIG.i18n.defaultLang

  const [htmlString, setHtmlString] = useState('')
  useEffect(() => {
    const data = validTypesArray.includes(offerId) ? { type: offerId, lang: langs.includes(lang) ? lang : defaultLang, defaultLang } : { offerId }

    dispatch(getOffersContentThunk(data))
      .unwrap()
      .then(({ content, url }) => {
        // if(url) navigateToOffer(url)
        setHtmlString(content)
      })
      .catch(() => {
        setHtmlString(t('l_common_wentWrong'))
      })

  }, [])

  return (
    <OfferBox textAlign={isMobile ? 'unset'  : 'justify !important'}>
      <StyledOfferView theme={theme}
        maxWidth={isMobile ? '85vw'  : '65vw'}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    </OfferBox>
  )
}

const OfferBox = styled.div`
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    font-size: 18px !important;

    & span {
        font-size: 18px !important;
    }

    & p {
        text-align: ${(props:any) => props.textAlign};
        margin-bottom: 15px;
    }

    & strong {
        font-size: 20px !important;
    }
`

const StyledOfferView = styled.div`
    margin: 50px auto 100px;
    max-width: ${(props:any) => props.maxWidth};
  
    & * {
      color: ${props => props.theme === 'dark' ? 'white !important' : null};
    }
`
export default OfferView
