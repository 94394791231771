import React, { memo } from "react"

import styled from "styled-components"
import { ModalDialog, ModalFooter, ModalHeader, PrimaryButton, SecondaryButton } from '@cloudike/web_ui_components'

import { appActions } from "../../../store/app"
import { useAppDispatch } from "../../../store"

export interface IModalSubmitTexts {
  TITLE: string
  CLOSE: string
  SUBMIT: string
  CONTENT: string
}

export interface ISubmitModal {
  handleCloseModal: () => void;
  handleSubmitModal: () => void;
  parentBlock: any;
  isDialogVisible: boolean;
  textsModalSubmit: IModalSubmitTexts;
  classnameContainer?: any;
}

const SubmitModal = ({ handleCloseModal, handleSubmitModal, parentBlock, isDialogVisible, textsModalSubmit, classnameContainer }: ISubmitModal) => {
  const dispatch = useAppDispatch()

  const handleVisibleModal = (value:boolean) => {
    dispatch(appActions.setVisibleModal(value))
  }

  return (
    <>
      <ModalDialog
        setIsModalVisible={handleVisibleModal}
        header={<ModalHeader title={textsModalSubmit.TITLE} />}
        footer={(
          <ModalFooter
            buttonList={[
              <SecondaryButtonBox
                key={textsModalSubmit.CLOSE}
                actionName={textsModalSubmit.CLOSE}
                className="modal-dialog__cancel"
                onAction={handleCloseModal}
              />,
              <PrimaryButton
                key={textsModalSubmit.SUBMIT}
                actionName={textsModalSubmit.SUBMIT}
                className="modal-dialog__button_action"
                onAction={handleSubmitModal}
              />
            ]}
          />
        )}
        parentBlock={parentBlock}
        isOpened={isDialogVisible}
        onCloseModal={handleCloseModal}
        classnameContainer={classnameContainer}
      >
        <TextContent>
          {textsModalSubmit.CONTENT}
        </TextContent>
      </ModalDialog>
    </>
  )
}

export const SubmitDeletePersonModal = memo(SubmitModal)

const SecondaryButtonBox = styled(SecondaryButton)`
  margin-right: 8px;
`

const TextContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-secondary);
  padding: 12px 0 16px;
  line-height: 20px;
`

