import { STYLED_VARIABLES } from 'constants/styledVariables'

import styled from 'styled-components'

export const ProfileTable = styled.table`
  width: 100%;
  min-width: 350px;
  table-layout: fixed;
`

export const ProfileTHead = styled.thead``

export const ProfileTHeadTr = styled.tr``

export const ProfileTHeadTh = styled.th`
  background-color: var(--background-tertiary);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-primary);
  padding: 5px 16px;
  border-bottom: 1px solid var(--divider-primary);
  overflow: hidden;
  text-align: left;

  &:first-child {
    padding-left: 8px;
  }

  &.align-right {
    text-align: right;
  }
`

export const ProfileTBody = styled.tbody``

export const ProfileTr = styled.tr``

export const ProfileTd = styled.td`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 12px 12px;
  border-bottom: 1px solid var(--divider-primary);
  overflow: hidden;
  text-align: left;

  &:first-child {
    padding-left: 8px;
  }
`

export const ProfileTableText = styled.p`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  &.align-right {
    display: flex;
    justify-content: flex-end;
  }
`

export const ProfileTableBox = styled.section`
  overflow-x: auto;
  width: auto;
`

export const ProfilePageContentBox = styled.div`
  padding: 0 16px;
  width: auto;
  height: calc(100% - 84px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
