import { STYLED_VARIABLES } from 'constants/styledVariables'

import React from 'react'

import { MenuItem } from '@cloudike/web_ui_components'
import { useAppDispatch } from  "store"
import { appActions } from 'store/app'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface MobileToolbarProps {
    className?: string,
    items?: React.ComponentProps<typeof MenuItem>[],
    headContent: React.ReactElement
}

export const MobileToolbar: React.FC<MobileToolbarProps> = ({ className = '', headContent, items = [] }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleCloseClick = () => {
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  return (
    <MobileToolbarBox className={className}>
      {headContent}

      <StyledMenuItemBox>
        {items.map(item => (
          <StyledMenuItem
            key={item.label}
            {...item}
          />
        ))}
      </StyledMenuItemBox>

      <CloseBtn onClick={handleCloseClick}>
        {t('a_common_close')}
      </CloseBtn>
    </MobileToolbarBox>
  )
}

const MobileToolbarBox = styled.div`
  label {
    padding: 17px 20px;
    border-bottom: 0.5px solid var(--divider-primary);
    border-top: 0.5px solid var(--divider-primary);
  }

  button {
    padding: 17px 20px;
    border-bottom: 0.5px solid var(--divider-primary);
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    display: none;
  }
`

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  outline: 0;
  border: none;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 32px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none !important;
  user-select: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  background-color: var(--button-tertiary-default);
  color: var(--text-primary);

  width: calc(100% - 48px);
  margin: 16px 24px !important;
`

const StyledMenuItem = styled(MenuItem)`
  padding: 17px 20px;
  line-height: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
  background-color: transparent;
  border: none;
  margin-bottom: 0;
  position: relative;
  border-bottom: 1px solid var(--divider-primary);
  border-top: 1px solid var(--divider-primary);
  color: var(--accent-normal);

  & ~ & {
    border-top: none;
  }

  svg {
    color: var(--text-secondary);
  }

  span {
    margin-left: 20px;
    color: var(--text-primary);
    text-align: left;
  }

  button {
    padding: unset;
    border: unset;

    svg {
      color: var(--text-secondary);
    }
  }
`

const StyledMenuItemBox = styled.div`
  max-height: calc(100vh - 115px);
  overflow-y: scroll;
`
