import { formatImgUrl, SIDEBAR_LOGO_URL, SIDEBAR_LOGO_URL_DARK } from 'constants/configs/theme.config'
import { STYLED_VARIABLES } from 'constants/styledVariables'

import React, { useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { PrimaryButton, SecondaryButton, SpriteIcon } from '@cloudike/web_ui_components'
import { getIsUserAuthorizedSelector, THEME, updateUserDataThunk } from 'features/user/userSlice'
import { redirectToExternalSSO } from 'utils/externalSSO'
import { useAppDispatch } from  "store"
import { joinToFamilyCloudThunk } from 'features/family/familySlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { getErrorData } from 'utils/getErrorData'
import { usePageTitle } from 'features/common/hooks/usePageTitle'

import { getUiTheme, getUserDataSelector } from "../../../features/user/selectors"

import { FamilyInvitationWarningModal } from "./FamilyInvitationWarningModal"


const FamilyInvitation = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const authorized = getIsUserAuthorizedSelector()
  const userData = getUserDataSelector()

  const [warningModalOpened, toggleWarningModal] = useState(false)

  const theme = getUiTheme()

  usePageTitle('l_common_invitation')

  const handleCloseWarningModal = () => {
    toggleWarningModal(false)
  }

  const handleManageFamilyClick = () => {
    navigate('/family/manage')
  }

  const handleDecline = () => {
    if (authorized) {
      navigate('/drive')
    } else {
      redirectToExternalSSO('', true)
    }
  }

  const handleJoin = () => {
    if (!authorized) {
      redirectToExternalSSO('', undefined, `${window.location.origin}/family/photos?fchash=${searchParams.get('fchash')}`)

      return
    }

    const successCallback = (familyUserId: number, ownerId: number) => {
      navigate('/family/photos?onboarding=1')
      dispatch(updateUserDataThunk({ family_user_id: familyUserId, is_owner_family: userData?.id === ownerId }))
    }

    const errorCallback = (error) => {
      if (error.code) {
        if (error.status === 409) {
          switch (error.code) {
          // if user was accepted
          case 'UserAlreadyMemberOfFamily':
            navigate('/family/photos')
            break
            // if user is 'owner' other family cloud
          case 'UserMemberOfOtherFamily':
            toggleWarningModal(true)
            break
          }
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_confirmationFailed'),
            message: error.data.description
          })
        }
      } else {
        if(error.toString() === 'NotFoundError') {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_notification_inviteLinkIncorrect'),
          })
        } else {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            ...getErrorData(error),
          })
        }
      }
    }

    dispatch(joinToFamilyCloudThunk({ hash: searchParams.get('fchash'), successCallback, errorCallback }))
  }

  return (
    <SPageBox>
      <SContent>
        <SLogoLink
          logo={theme === THEME.LIGHT ? SIDEBAR_LOGO_URL : SIDEBAR_LOGO_URL_DARK}
          href="/"
          title={t('l_common_appName')}
        />

        <SMainContentBox>
          <SInfoContent>
            <SInfoContentLeft>
              <SInfoTitle>
                {t('l_family_inviteTitle')}
              </SInfoTitle>

              <SInfoDescription>
                {t('l_familycloud_beenInvitedMessage')}
              </SInfoDescription>

              <SButtonsBox>
                <SSecondaryButton
                  actionName={t('a_common_decline')}
                  onAction={handleDecline}
                />

                <SPrimaryButton
                  actionName={t('a_common_join')}
                  onAction={handleJoin}
                />
              </SButtonsBox>
            </SInfoContentLeft>

            <SInfoContentRight>
              <SInfoImg
                src={theme === THEME.LIGHT ? formatImgUrl("/assets/placeholders-images/family-create.svg") : formatImgUrl("/assets/placeholders-images-black/family-create.svg")}
              />
            </SInfoContentRight>
          </SInfoContent>

          <SFullWidthDivider />

          <SInfoOptionsContent>
            <SOptionColumn>
              <SOptionIcon iconName='multiple_actions_add_2' />

              <div>
                <SOptionTitle>
                  {t('l_familycloud_inviteFamily')}
                </SOptionTitle>

                <SOptionDescription>
                  {t('l_familycloud_inviteFamilyDescription')}
                </SOptionDescription>
              </div>
            </SOptionColumn>

            <SOptionColumn>
              <SOptionIcon iconName='picture_polaroid_landscape' />

              <div>
                <SOptionTitle>
                  {t('l_familycloud_viewTogether')}
                </SOptionTitle>

                <SOptionDescription>
                  {t('l_familycloud_viewTogetherDescription')}
                </SOptionDescription>
              </div>
            </SOptionColumn>

            <SOptionColumn>
              <SOptionIcon iconName='responsive_design' />

              <div>
                <SOptionTitle>
                  {t('l_familycloud_accessEasy')}
                </SOptionTitle>

                <SOptionDescription>
                  {t('l_familycloud_accessEasyDescription')}
                </SOptionDescription>
              </div>
            </SOptionColumn>
          </SInfoOptionsContent>
        </SMainContentBox>
      </SContent>

      <FamilyInvitationWarningModal
        opened={warningModalOpened}
        onOk={handleManageFamilyClick}
        onClose={handleCloseWarningModal}
      />
    </SPageBox>
  )
}

const SPageBox = styled.div`
  width: 100vw;
  background: var(--background-banner);
  display: flex;
  justify-content: center;
  padding: 32px 16px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    padding: 56px 0 0;
    background: unset;
  }
`

const SContent = styled.div`
  width: 100%;
  max-width: 1160px;
`

const SLogoLink = styled.a`
  margin-left: 16px;
  display: block;
  background: ${props => `url(${props.logo}) no-repeat`};
  background-size: contain;
  align-items: center;
  padding-left: 50px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  min-width: 112px;
  min-height: 32px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-left: unset;
    min-width: 176px;
    min-height: 52px;
  }
`

const SMainContentBox = styled.div`
  margin-top: 24px;
  width: 100%;
  background: var(--background-secondary);
  min-height: 468px;
  border: 1px solid var(--divider-primary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0;
  
  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    min-height: unset;
    width: 100%;
    background: unset;
    border: unset;
    display: block;
  }
`

const SOptionColumn = styled.div`
  flex: 1;
  display: flex;

  & ~ & {
    margin-top: 24px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: block;

    & ~ & {
      margin-top: 0;
      margin-left: 48px;
    }
  }
`

const SOptionIcon = styled(SpriteIcon)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  color: var(--active-color, var(--button-primary-default));

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-right: 8px;
  }
`

const SOptionTitle = styled.p`
  color: var(--text-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 12px;
  }
`

const SOptionDescription = styled.p`
  margin-top: 4px;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const SInfoContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 0 24px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    flex-direction: row;
    padding: 96px 0;
    align-items: unset;
  }
`

const SInfoOptionsContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 40px 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: flex;
    margin-top: 25px;
    flex-direction: row;
    padding: 0 0 32px;
    align-items: center;
  }
`

const SFullWidthDivider = styled.div`
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid var(--divider-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
  }
`

const SInfoContentLeft = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    width: 580px;
    padding-top: 53px;
    display: block;
  }
`

const SInfoContentRight = styled.div`
  width: 256px;
  padding-top: 32px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-left: 77px;
    width: 543px;
    padding-top: 0;
  }
`

const SInfoImg = styled.img`
  width: 256px;
  height: auto;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    width: 443px;
  }
`

const SInfoTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);
  text-align: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    font-size: 32px;
    line-height: 130%;
    text-align: unset;
  }
`

const SInfoDescription = styled.p`
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--text-primary);
  text-align: center;
  width: 295px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 165%;
    width: unset;
    text-align: unset;
  }
`

const SButtonsBox = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
  }
`

const SPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
  min-width: 144px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-left: 16px;
    width: unset;
  }
`

const SSecondaryButton = styled(SecondaryButton)`
  margin-top: 16px;
  width: 100%;
  height: 40px;
  min-width: 144px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 0;
    width: unset;
  }
`


export default FamilyInvitation
