import { useCallback, useEffect } from "react"

import { checkTelegramBotThunk, getTelegramLinkThunk, THEME, userActions } from 'features/user/userSlice'
import { useAppDispatch } from  "store"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getTelegramTokenSelector, getUiTheme } from 'features/user/selectors'
import { PrimaryButton } from "@cloudike/web_ui_components"

import { LOADING_STATUSES } from "../../../../constants/loadingStatuses"
import telegramIcon from "../../../../assets/icons/telegram_logo.svg"
import telegramIconDark from "../../../../assets/icons/telegram_logo_dark.svg"

export const ProfileTelegramBot = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const telegramBot = getTelegramTokenSelector()
  const theme = getUiTheme()

  useEffect(() => {
    dispatch(checkTelegramBotThunk())

  	return () => {
      dispatch(userActions.resetTelegramBot())
  	}
  }, [])

  const handleGetToken = useCallback(() => {
    dispatch(getTelegramLinkThunk())
  },[])

  if (!telegramBot.enable) return null

  return (
    <ProfileTelegramBotBox>
      <Title>
        {t('l_settings_botTitle')}
      </Title>

      <DescriptionText>
        {t('l_settings_tgBotNotConnected')}
      </DescriptionText>

      <PrimaryButton
        onAction={handleGetToken}
        disabled={telegramBot.status === LOADING_STATUSES.LOADING}
        actionName={t('a_settings_openBot')}
      />

      <TelegramIcon src={theme === THEME.LIGHT ? telegramIcon : telegramIconDark}
        alt="Telegram"
      />
    </ProfileTelegramBotBox>
  )
}

const ProfileTelegramBotBox = styled.div`
  position: relative;
  margin-top: 16px;
  padding: 24px;
  background: var(--background-secondary);
`

const DescriptionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 17px;
  position: relative;
  z-index: 1;
`

const Title = styled.h2`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-primary);
`

const TelegramIcon = styled.img`
  width: 128px;
  height: 128px;
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--background-secondary);
`
