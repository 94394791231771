import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { useNavigate, useSearchParams } from "react-router-dom"
import _ from "lodash"

import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { REDIRECT_TO } from "../../../constants/searchParams"
import { appActions } from "../../../store/app"
import { getTimelineSelectedItemsCountSelector, getTimelineSelectedItemsSelector } from "../../photo/timeline/selectors"
import { AddingToolbar } from "../../common/add-toolbar/AddingToolbar"

import { getAlbumDataSelector } from "./selectors"
import { addItemsToAlbumThunk } from "./albumSlice"

export const AddingPhotosToAlbumToolbar = ({ type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = getTimelineSelectedItemsCountSelector()
  const albumData = getAlbumDataSelector()

  const redirectToUrl = searchParams.get(REDIRECT_TO)

  const handleBack = () => {
    // if 'redirect_to' is in searchParams, go back to this url
    if (!_.isNil(redirectToUrl)) {
      navigate(redirectToUrl)
      return
    }

    if (type === SDK_TYPES.DEFAULT) {
      navigate(`/photos/albums/${albumData.id}`)
    }

    if (type === SDK_TYPES.FAMILY) {
      navigate(`/family/photos/albums/${albumData.id}`)
    }
  }

  const handleAddPhotos = useCallback(() => {
    dispatch(addItemsToAlbumThunk({ items: selectedItems, callback: handleBack, t }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }, [searchParams, selectedItems])


  return (
    <AddingToolbar onAction={handleAddPhotos}
      actionName={t('l_albums_addToAlbum')}
      selectedItemsCount={selectedItemsCount}
    />
  )
}
