import React, { useRef } from "react"

import styled from "@emotion/styled"
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { FilesUploadingWidget } from "../files-uploading/FilesUploadingWidget"
import { Notifications } from "../notifications"
import { getIsMobileMenuVisible } from "../selectors"
import { TransformerWidget } from "../transformer-widget/TransformerWidget"
import { getUserSettingsByPathSelector } from "../../user/selectors"
import { USER_SETTINGS_PATHS } from "../../user/constants"

const NotifAndUploadWidget = () => {
  const isMenuVisible = getIsMobileMenuVisible()
  const isEnableTransformerPhoto = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.TRANSFORMER)?.enabled
  const notifAndWidgetBoxRef = useRef<HTMLDivElement>(null)

  return (
    <NotifAndUploadWidgetBox ref={notifAndWidgetBoxRef}
      bottom={ isMenuVisible ? '86px' : '0' }
    >
      <Notifications parentRef={notifAndWidgetBoxRef} />

      <WidgetBox>
        {isEnableTransformerPhoto && <TransformerWidget />}

        <FilesUploadingWidget />
      </WidgetBox>

    </NotifAndUploadWidgetBox>
  )
}

const WidgetBox = styled.div`
  width: 456px;
  position: fixed;
  right: 32px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  order: 2;

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    position: unset;
    right: unset;
    bottom: unset;
    width: unset;
  }
`

const NotifAndUploadWidgetBox = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 14;
  bottom: ${(props:{bottom: string}) => props.bottom };

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 100%;
  }

  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 0 0 24px 24px;
    bottom: 0;
  }
`

export default NotifAndUploadWidget
